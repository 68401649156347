@import "styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table {
  display: flex;
  flex: 1 1 auto;
  row-gap: 20px;
  height: max-content;
  overflow-y: auto;
  margin: 0 -10px 15px -20px;
  padding: 10px 10px 4px 20px;
  max-height: calc(100vh - 278px);
  gap: 16px;
  padding-top: 24px;
}

.tableUser {
  flex: 0 0 330px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tableColumns {
  width: 100%;
  display: flex;
  overflow-x: auto;
  margin-top: -24px;
  gap: 8px;
}

.skeleton {
  margin-bottom: 10px;
}
