@import "styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.sort-container {
  display: flex;
}

.sort__button {
  display: flex;
  align-items: center;
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 6px 16px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:hover {
    box-shadow: 0px 0px 21px -2px rgba(0, 0, 0, 0.09);
  }

  &-text {
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: $totalBlackColor;
    margin-right: 16px;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.icons-list {
  &__icon-arrow {
    width: 24px;
    height: 24px;
    stroke: $totalBlackColor;
    transform: rotate(90deg);
    transition: stroke 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &__icon-az {
    width: 10px;
    height: 21px;
    fill: $totalBlackColor;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &__icon-calendar {
    width: 14px;
    height: 15px;
    fill: $totalBlackColor;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.sort__button.active {
  &--asc {
    .icons-list__icon-arrow {
      transform: rotate(90deg);
    }

    .sort__button-text {
      color: $activeColor;
    }

    .icons-list__icon-arrow {
      stroke: $activeColor;
    }

    .icons-list__icon-az,
    .icons-list__icon-calendar {
      fill: $activeColor;
    }
  }

  &--desc {
    .icons-list__icon-arrow {
      transform: rotate(270deg);
    }

    .sort__button-text {
      color: $activeColor;
    }

    .icons-list__icon-arrow {
      stroke: $activeColor;
    }

    .icons-list__icon-az,
    .icons-list__icon-calendar {
      fill: $activeColor;
    }
  }
}

.byname {
  .sort__button-text {
    margin-right: 5px;
  }
}

.preview__button {
  width: 36px;
  height: 36px;
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  &:hover {
    .preview__button-icon {
      stroke: $hoverColor;
    }
  }

  &-icon {
    width: 24px;
    height: 24px;
    stroke: $totalBlackColor;
    transform: rotate(180deg);
    transition: stroke 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &.rotate {
      transform: rotate(90deg);
    }
  }
}
