@import "styles/variables.scss";

.container {
  width: 11%;
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.input {
  height: 24px;
  border: none;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  color: $blueColor;
  text-align: center !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  padding-left: 0 !important;
  text-overflow: ellipsis;
  caret-color: $blackColor;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:focus {
    color: $halfAccent;
    outline: 1px solid $blueColor;

    &::placeholder {
      color: $blackColor;
    }
  }

  &::placeholder {
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: $greyColor;
  }

  &:hover {
    &::placeholder {
      color: $blackColor;
    }
  }
}

.input::-webkit-calendar-picker-indicator {
  display: none;
}

.label {
  text-align: center;
  margin-left: 0;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &--active {
    cursor: pointer;
    color: $blueColor !important;
    text-align: center;
    margin-left: 0;
  }

  &:hover {
    color: $hoverColor;
  }

  &:active {
    color: $activeColor;
  }
}

.timeInputs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__from {
    width: 35%;
  }

  &__to {
    width: 35%;
  }
}

.timeLabel {
  margin-top: 5px;
  margin-left: 0 !important;
  color: $blueColor !important;
}

.check {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: block;

  ellipse {
    fill: $halfAccent;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover ellipse {
    fill: $blueColor;
  }
}

.cross {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: block;

  circle {
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover circle {
    fill: $errorColor;
  }
}
