.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  column-gap: 2%;
  row-gap: 30px;
  height: max-content;
  max-height: calc(100vh - 220px);
  overflow: visible auto;
  margin: -20px 0 0 -30px;
  padding: 20px 27px 20px 30px;

  @media screen and (min-width: 1920px) {
    grid-template-columns: repeat(auto-fit, minmax(475px, 502px));
  }

  &--single {
    grid-template-columns: 1fr;
  }
}

.skeleton-container {
  display: flex;
  column-gap: 2%;

  br {
    display: none;
  }
}
