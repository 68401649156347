@import "styles/variables.scss";

.container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.input[placeholder] {
  text-overflow: ellipsis;
}

.input {
  height: 24px;
  border: none;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  caret-color: $blackColor;
  color: $blueColor;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &::placeholder {
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:focus {
    outline: 1px solid $blueColor;
  }
}

.repeatPassword {
  margin-top: 10px !important;
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 11px;
  transition: stroke 0.2s ease-out, fill 0.2s ease-out;

  &--fill {
    fill: $blackColor;
    stroke: none;
  }
}

.oldPassword {
  label {
    margin-bottom: 6px;
  }
}

.wrapper {
  position: relative;

  & ~ p {
    display: none;
    font-weight: 300;
    font-size: 0.5rem;
    line-height: 10px;
    color: $errorColor;
    margin-top: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

[data-error] {
  .input {
    color: $errorColor;
  }

  .wrapper ~ p {
    display: block;
  }
}

.eye {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: calc(50% - 12px);

  &__icon {
    display: block;
    width: 24px;
    height: 24px;

    path {
      fill: $greyColor;
      transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover {
      path {
        fill: $greyColor2;
      }
    }
  }

  &__icon--show {
    path {
      fill: $blackColor;
    }

    &:hover {
      path {
        fill: $blackColor;
      }
    }
  }
}

.error {
  width: 100%;
  text-align: inherit;
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 10px;
  margin-top: 2px;
  color: $errorColor2;
}
