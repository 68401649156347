@import "styles/variables.scss";

.container {
  padding: 20px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.image {
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title {
  text-align: center;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 22px;
  color: $totalBlackColor;
  max-width: 234px;
  margin-bottom: 2px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.open {
  width: 24px;
  height: 24px;
  margin-top: auto;

  &:hover {
    .open__icon {
      fill: $hoverColor;
    }
  }

  &__icon {
    height: 7.12px;
    width: 15.37px;
    fill: $blackColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &--open {
    transform: rotate(180deg);
  }
}
