@import "styles/variables.scss";

.minSizeContainer {
  width: 100%;
  color: $blackColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 14px 10px 0 10px;
}

.minSizeAvatar {
  width: 54px;
  height: 54px;
  border-radius: 100px;
  object-fit: cover;
  transition: width 0.12s ease-out, height 0.12s ease-out;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.minSizeInfoContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.arrow {
  transition: transform 0.25s ease-out;

  &__icon {
    height: 7.12px;
    width: 15.37px;
    fill: $blackColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:active &__icon {
    fill: $blueColor;
  }

  &:hover &__icon {
    fill: $hoverColor;
  }

  &--open {
    transform: rotate(180deg);
  }
}

.name {
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.job {
  margin-top: 2px;
  color: $greyColor;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
