@import "styles/variables.scss";

.mentor {
  display: flex;
  flex-direction: column;
  align-items: center;

  &--medSize {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 82px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    margin-top: 12px;

    &--medSize {
      font-weight: 500;
      font-size: 20px;
      line-height: 18px;
      margin-left: 35px;
    }
  }

  &__avatar {
    width: 118px;
    height: 118px;
    margin-top: 22px;

    &--medSize {
      width: 54px;
      height: 54px;
      position: absolute;
      left: -35px;
    }
  }

  &__description {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $greyColor;
    margin-top: 10px;
    width: 100px;
    text-align: center;
    margin-bottom: 10px;

    &--medSize {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $greyColor;
      margin-top: 5px;
      margin: 5px 0 0 35px;
    }
  }
}
