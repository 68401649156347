@import "styles/variables.scss";

.header {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  height: 50px;
  background-color: $backgroundGrey;
  border-bottom: 1px solid $greyColor3;
  position: relative;
  section {
    width: 100%;
    margin-left: 0px;
    height: 50px;
  }
}

.searchIcon {
  height: 16px;
  width: 16px;
}

.autocomplite {
  width: inherit;
  max-height: 260px;
  position: absolute;
  top: 60px;
  right: 0;
  overflow: hidden;
  z-index: 300;
  background-color: #fff;
}
.autocomplitePadding {
  padding: 16px 8px 16px 16px;
  width: 100%;
}
.autocompliteList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: inherit;
  scrollbar-color: #f2f2f2 #e2e2e2;
  max-height: 160px;
  padding-right: 8px;
  padding-bottom: 28px;
  width: 100%;
  &::-webkit-scrollbar {
    width: "7px";
    height: "7px";
  }

  &::-webkit-scrollbar-track {
    background: "#F2F2F2";
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f2f2f2;
    border-radius: 30px;
    border: 1px solid #f2f2f2;
  }
}
.autocompliteListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 6px 16px;
  border: 2px solid #f2f2f2;
  border-radius: 8px;
  cursor: pointer;
  height: 40px;
}
.autocompliteListItemName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: #313131;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
}

.active {
  border-color: $activeColor;
}

.autocompliteOverlay {
  top: 0;
  position: fixed;
  background-color: transparent;
  width: 100%;
  height: 100%;
  left: 0;
}

.open {
  path {
    fill: $blueColor;
    color: $blueColor;
  }
}
