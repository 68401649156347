@import "styles/variables.scss";

.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 8px;

  &__text {
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: inherit;
  }

  &__icon {
    width: 24px;
    height: 24px;
    fill: $whiteColor;
    margin-right: 6px;
  }
}

.outline {
  color: $greyColor4;
  background-color: $backgroundGrey;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    border 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid $greyColor3;
  &:hover {
    border-color: $activeColor;
  }

  &--success {
    background-color: $activeColor;
    color: $whiteColor;

    &:hover {
      background-color: $activeColor;
    }
  }

  &:disabled {
    background-color: $backgroundGrey;
    color: $greyColor4;
    border-color: #e2e2e2;
    opacity: 0.5;
  }
}

.fill {
  height: 40px;
  color: $whiteColor;
  background-color: $activeColor;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    border 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  &:hover {
    background-color: $hoverColor;
  }
  span {
    color: $whiteColor;
  }
  &--success {
    background-color: $activeColor;
    color: $whiteColor;

    &:hover {
      background-color: $hoverColor;
    }
  }

  &:disabled {
    background-color: $hoverColor;
    color: $whiteColor;
    opacity: 0.8;
  }
}

.outline-shadow {
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    border 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: $whiteColor;
  color: $blackColor;
  &:hover {
    border: 1px solid $hoverColor;
  }

  &--success {
    background-color: $activeColor;
    color: $whiteColor;

    &:hover {
      background-color: $activeColor;
    }
  }
}

.default {
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border: none;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: $whiteColor;
  color: $blackColor;
  &:hover {
    color: $whiteColor;
    background-color: $hoverColor;
  }

  &:active {
    background-color: $activeColor;
  }

  &--success {
    background-color: $activeColor;
    color: $whiteColor;

    &:hover {
      background-color: $activeColor;
    }
  }
}
