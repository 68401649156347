@import "styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0;
}

.filter {
  width: 100%;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 20px;
  display: flex;
  padding: 20px;
  justify-content: space-between;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);
  }
}

.inputs {
  display: flex;
  align-items: flex-start;

  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $greyColor;
    margin-right: 10px;
  }

  section {
    flex-direction: row;
    align-items: flex-end;
    width: clamp(115px, 8vw, 200px);
  }

  section:last-child {
    margin-right: 20px;
  }

  @media screen and (max-width: 1250px) {
    width: 75%;
    flex-direction: column;
    align-items: center;

    & section {
      width: 100%;
      margin: 5px 0;
      align-items: center;

      & label {
        width: 25px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

.inputsOpen {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 20px 0 20px 0;

  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $greyColor;
    margin-right: 10px;
  }

  @media screen and (max-width: 1250px) {
    width: 75%;
    flex-direction: column;
    align-items: center;

    & section {
      width: 100%;
      align-items: center;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.inputsTM {
  display: flex;
  align-items: flex-start;

  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $greyColor;
    margin-right: 10px;
  }

  section {
    flex-direction: row;
    align-items: flex-end;
    width: clamp(115px, 8vw, 200px);
  }

  section:last-child {
    margin-right: 20px;
  }

  @media screen and (max-width: 1250px) {
    width: 75%;
    flex-direction: column;
    align-items: center;

    & section {
      width: 100%;
      margin: 5px 0;
      align-items: center;

      & label {
        width: 25px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}

.inputsOpenTM {
  display: flex;
  align-items: flex-start;

  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: $greyColor;
    margin-right: 10px;
  }

  @media screen and (max-width: 1250px) {
    width: 75%;
    flex-direction: column;
    align-items: center;

    & section {
      width: 100%;
      align-items: center;
      margin: 5px 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.inlineDate {
  display: flex;
}

.calendar {
  box-shadow: 0px 0px 14px -2px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  transition: background 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: $halfAccent;
  }

  &__icon {
    width: 16px;
    height: 16px;
    fill: $blackColor;
  }
}

.openedCalendarsContainer,
.openedCalendarsContainerTM {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 62%;

  @media screen and (max-width: 1250px) {
    margin: 10px 0;
  }
}

.openedCalendars {
  display: flex;
  justify-content: space-between;

  section {
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: fit-content;

    div {
      box-shadow: none;
    }

    label {
      margin-bottom: 6px;
    }
  }
}

.controls {
  display: flex;
  align-items: center;

  & > div:last-child {
    margin-left: 10px;
  }
}

.check {
  width: 34px;
  height: 34px;

  ellipse {
    fill: $halfAccent;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover ellipse {
    fill: $blueColor;
  }
}

.close {
  width: 33px;
  height: 33px;
  display: block;

  circle {
    fill: $errorColor2;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover circle {
    fill: $errorColor;
  }
}

.activeLink {
  color: $blueColor;
  width: max-content;
}

a {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:active {
    color: $blueColor;
  }
}

.table {
  width: 100%;
  margin: 20px 0px;
  padding-bottom: 30px;

  @media screen and (min-height: 536px) {
    overflow-y: auto;
  }
}

.skeleton {
  width: 100%;
  padding: 30px 30px 20px 30px;

  br {
    display: none;
  }
}

.skeletonTable {
  width: 100%;
}

.breadcrumbs {
  width: 100%;
}

.breadcrumb {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: $hoverColor;
  }

  &:last-child {
    cursor: default;
    color: $blueColor;
  }
}
