@import "styles/variables.scss";

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $blackColor;
  padding: 10px 15px;

  @media (max-width: 650px) {
    display: none;
  }
}

.logo-container {
  width: 100px;
  height: 40px;

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.user {
  display: flex;
  align-items: center;

  &__name {
    margin-right: 10px;
    color: $whiteColor;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
  }
}

.photo-container {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.skeletons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.skeleton-container {
  span {
    --base-color: #dbdbdb;
  }

  br {
    display: none;
  }
}
