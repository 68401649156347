@import "styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 292px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  [data-error] input:not(:focus) {
    outline: 1px solid $errorColor2;
    color: $errorColor;
  }

  section {
    width: 100%;
    margin: 0;
  }

  input {
    width: 100%;
    height: 36px;
    padding: 10px 30px 10px 20px;
    text-align: left;
    caret-color: $blackColor;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: $blueColor;
    text-overflow: ellipsis;

    &:focus {
      color: $halfAccent;

      &::placeholder {
        color: $blackColor;
      }
    }

    &::placeholder {
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      color: $greyColor;
    }

    &:hover {
      &::placeholder {
        color: $blackColor;
      }
    }
  }

  button {
    width: 100%;
    height: 36px;
    box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      background-color: rgba(0, 194, 250, 0.6);
      color: $whiteColor;
    }

    &:active {
      background-color: $activeColor;
    }
  }
}

.titleForm {
  font-weight: 300;
  font-size: 10px;
  line-height: 16px;
  color: $blackColor;
  display: block;
  margin-bottom: 15px;
}

.skeleton-container {
  span:first-child {
    height: 16px !important;
  }
}
