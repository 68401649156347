@import "styles/variables.scss";

.btnGroup {
  display: none;
  @media (min-width: 1020px) {
    gap: 8px;
    display: flex;
    align-items: center;
    background-color: $backgroundGrey;
    border-radius: 8px;
    min-height: 40px;
    width: fit-content;
  }
}
.btnGroupItem {
  padding: 8px 16px;
  min-height: 40px;
  background-color: $backgroundGrey;
  border-radius: 8px;
  color: $darkGrey;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 16px;
  font-weight: 500;
  width: max-content;
  @media (hover: hover) {
    &:hover {
      color: $greyColor4;
    }
  }
  &.active {
    color: $greyColor4;
    background-color: $greyColor3;
  }
}
