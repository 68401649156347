@import "styles/variables.scss";

.container {
  min-width: 90px;
  width: 1px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
  gap: 12px;
  height: calc(100vh - 60px);
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.03);
  background: $whiteColor;
  padding: 20px;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    min-width 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &--open {
    width: 252px;
    min-width: 252px;

    .top-container {
      width: 100%;
    }

    .logout {
      width: 16px;
      height: 16px;
      margin-right: 0;
    }

    .title {
      margin-left: 2px;
      width: 112px;
      transition: color 0.2s ease-out, width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    .bug__icon {
      width: 16px;
      height: 16px;
    }

    .bug .title {
      width: 144px;
    }
  }

  @media (max-width: 650px) {
    height: 100vh;
    min-width: 50px;
    padding: 10px 0;
  }
}

.top-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.burger {
  border: none;
  background: none;
  padding: 0;
  display: block;
  outline: none;
  margin-left: auto;
  margin-top: 12vh;

  @media screen and (max-height: 650px) {
    margin-top: max(20px, 4vh);
  }

  @media screen and (max-width: 1280px) {
    display: none;
  }
}

.logout {
  fill: $blackColor;
  transition: fill 0.25s ease-out, width 0.2s linear, height 0.2s linear;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.title {
  font-size: 1rem;
  line-height: 16px;
  transition: color 0.2s ease-out;
  max-width: max-content;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.button {
  display: inline-flex;
  align-items: center;

  &:hover {
    .logout {
      fill: $hoverColor;
    }

    .title {
      color: $hoverColor;
    }
  }

  &:active {
    .logout {
      fill: $blueColor;
    }

    .title {
      color: $blueColor;
    }
  }
}

.button--confirm {
  .logout {
    fill: $errorColor2;
  }

  .title {
    color: $errorColor2;
  }

  &:hover {
    .logout {
      fill: $errorColor;
    }

    .title {
      color: $errorColor;
    }
  }
}

.bug {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  &__icon {
    width: 30px;
    height: 30px;
    display: block;
    transition: width 0.2s linear, height 0.2s linear;

    path {
      fill: $blackColor;
      transition: fill 0.25s ease-out;
    }
  }

  &:hover {
    .bug__icon {
      path {
        fill: $hoverColor;
      }
    }

    .title {
      color: $hoverColor;
    }
  }

  &:active {
    .bug__icon {
      path {
        fill: $blueColor;
      }
    }

    .title {
      color: $blueColor;
    }
  }
}

.buttom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.photo-container {
  display: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-bottom: 12px;
  overflow: hidden;

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 650px) {
    display: block;
  }
}

.skeleton-container {
  span {
    --base-color: #dbdbdb;
  }

  br {
    display: none;
  }
}
