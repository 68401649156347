@import "styles/variables.scss";

.search {
  width: 100%;
  padding: 8px 16px 8px 36px;
  background: $whiteColor;
  border-radius: 8px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  color: $blueColor;
  font-weight: 500;
  font-size: 1rem;
  line-height: 16px;
  caret-color: $blackColor;
  border: 2px solid $backgroundGrey;
  margin-bottom: 12px;

  &:focus {
    color: $halfAccent;
    outline: 1px solid $blueColor;
  }

  &::placeholder {
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: $darkGrey;
  }

  &:hover {
    &::placeholder {
      color: $blackColor;
    }
  }

  background: url("/assets/img/icons/search.svg") no-repeat right;
  background-position-x: 16px;

  &--error {
    background: url("/assets/img/icons/search-error.svg") no-repeat right;
    background-position-x: 16px;
    color: $errorColor;
  }
}
.formControlAuto {
  position: relative;
}

.searchWrap {
  position: relative;
  &.padding {
    padding-bottom: 4px;
  }
}
.label {
  color: #999;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
}
.autocomplite {
  width: inherit;
  max-height: 160px;
  position: absolute;
  top: 44px;
  left: 0;
  overflow: hidden;
  z-index: 300;
  background-color: #fff;
}
.autocomplitePadding {
  padding: 16px 8px 16px 16px;
  width: 100%;
}
.autocompliteList {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: inherit;
  scrollbar-color: #f2f2f2 #e2e2e2;
  max-height: 160px;
  padding-right: 8px;
  padding-bottom: 28px;
  width: 100%;
  &::-webkit-scrollbar {
    width: "7px";
    height: "7px";
  }

  &::-webkit-scrollbar-track {
    background: "#F2F2F2";
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f2f2f2;
    border-radius: 30px;
    border: 1px solid #f2f2f2;
  }
}
.autocompliteListItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 6px 16px;
  border: 2px solid #f2f2f2;
  border-radius: 8px;
  cursor: pointer;
  height: 40px;
}
.autocompliteListItemName {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  overflow: hidden;
  color: #313131;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
}

.active {
  border-color: $activeColor;
}

.inputWrap {
  width: inherit;
  position: relative;
}
.inputEndAdornment {
  padding-right: 32px;
}
.endAdornment {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 10px;
  top: 50%;
  transform: translateY(-86%);
  z-index: 20;
  cursor: pointer;
}
