.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 1279px) {
    grid-column-start: -3;
    grid-column-end: -1;
  }
}

.projects-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
