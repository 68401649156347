@import "styles/variables.scss";

.container {
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 1px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1px;
  grid-gap: 20px;
}

.container[data-unit="week"] {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
}

.container[data-unit="month"] {
  grid-template-columns: 1fr;
  align-items: center;

  .prev {
    margin: 0;
  }
}

.monthContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.weekday[data-unit="month"] {
  flex: 0 0 200px;
  text-align: center;
}

.slider {
  width: 100%;
}

.prev,
.next {
  fill: $blackColor;
  transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.prev {
  transform: rotate(90deg);
  margin-left: -15px;
}

.next {
  transform: rotate(-90deg);
}

.date {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: $blackColor;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.day {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  color: $greyColor;
  margin-top: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dayoff .date {
  color: $orangeColor;
  overflow: hidden;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
}
