@import "styles/variables.scss";

.container {
  width: 100%;
  height: max(802px, 100%);
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px;
  position: relative;

  @media (max-width: 1500px) {
    height: fit-content;
  }

  @media (max-width: 650px) {
    box-shadow: none;
    padding: 20px 15px 15px 5px;
  }
}

.formContainer {
  width: 100%;
}

.addTask {
  width: 100%;
  border-bottom: 2px solid $greyColor;
  position: relative;

  &--open {
    width: 100%;
    border-bottom: 2px solid $blueColor;
    position: relative;
  }
}

.plus {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -21px;
  left: -10px;
  cursor: pointer;

  circle {
    fill: $hoverColor;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  path {
    fill: $whiteColor;
  }

  &:hover {
    circle {
      fill: $blueColor;
    }
  }
}

.cross {
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: -18px;
  left: -8px;
  cursor: pointer;
  z-index: 1;

  circle {
    fill: $errorColor2;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  path {
    fill: $whiteColor;
  }

  &:hover {
    circle {
      fill: $errorColor;
    }
  }
}

.check {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: -18px;
  right: -4px;
  cursor: pointer;

  ellipse {
    fill: $hoverColor;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover ellipse {
    fill: $blueColor;
  }

  &--close {
    display: none;
  }
}

.skeletonTable {
  width: 100%;

  span:first-child {
    height: 85px !important;
    margin-bottom: 20px !important;
  }
}
