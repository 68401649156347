@import "styles/variables.scss";

.photo-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 25px;
  padding: 0 10px;
  width: 100%;

  section {
    @media (max-width: 460px) {
      margin-top: 30px;
    }
  }

  input[type="file"] + label {
    width: 110px;
    height: 110px;
    position: relative;
    cursor: pointer;

    &:hover {
      & > svg {
        width: 31px;
        height: 31px;

        circle {
          fill: $activeColor;
        }
      }
    }

    & > img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    & > svg {
      width: 39px;
      height: 39px;
      position: absolute;
      right: 0;
      bottom: -8px;
      transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        height 0.3s cubic-bezier(0.4, 0, 0.2, 1);

      circle {
        fill: $hoverColor;
        transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      }

      path {
        fill: $whiteColor;
      }
    }
  }
}

.form {
  height: 100%;
  display: grid;
  grid-template-columns: 272px;
  row-gap: 20px;
  padding: 0 10px 20px 10px;

  @media (max-width: 1020px) {
    display: flex;
    flex-direction: column;
    padding: 0 16% 30px;
  }

  section {
    width: 100%;
    margin-left: 0;
    display: block;

    &[data-error] {
      input[name="telegram"] {
        background: url("/assets/img/icons/telegram-error.svg") no-repeat right !important;
        background-position-x: 6px !important;
      }

      input:not(input[id^="react-select"]):not(input[name="name"]) {
        color: $errorColor;
        outline: 1px solid $errorColor2;
      }
    }
  }

  label {
    width: 100%;
    margin: 0 0 12px 0;
    display: flex;
    justify-content: space-between;

    & > span:nth-child(1) {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 16px;
      color: $blackColor;
      max-width: 140px;
    }

    & > span:nth-child(2),
    & > a:nth-child(2) {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 16px;
      color: $greyColor;
      max-width: 130px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  textarea {
    box-shadow: none;
    border: none;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 29px;
    color: $blackColor;
    padding: 0 30px;
    background: url("/assets/img/icons/redact-black.svg") no-repeat left;
    text-align: center;
    outline: none;
    height: auto;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      background 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    @media (max-width: 450px) {
      font-size: 1.1rem;
      word-wrap: normal;
    }

    &::placeholder {
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      color: $blackColor;
    }

    &:hover {
      background: url("/assets/img/icons/redact-accent.svg") no-repeat left;
      color: $greyColor;

      &::placeholder {
        color: $greyColor;
      }
    }

    &:focus {
      background: url("/assets/img/icons/redact-accent.svg") no-repeat left;
      color: $halfAccent;
      outline: none;
    }
  }

  input:not(input[id^="react-select"]) {
    width: 100%;
    height: max-content;
    border: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
      inset 0px 0px 4px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    padding: 4px 8px;
    text-align: center;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 16px;
    caret-color: $blackColor;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      background 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      padding 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: $blueColor;
    text-overflow: ellipsis;

    &:focus {
      color: $halfAccent;
      outline: 1px solid $blueColor;

      &[name="telegram"] {
        background: url("/assets/img/icons/telegram.svg") no-repeat right;
        background-position-x: 6px;
      }

      &::placeholder {
        color: $blackColor;
      }
    }

    &::placeholder {
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      color: $greyColor;
    }

    &:hover {
      &[name="telegram"] {
        background: url("/assets/img/icons/telegram.svg") no-repeat right;
        background-position-x: 6px;
      }

      &::placeholder {
        color: $blackColor;
      }
    }

    &[name="telegram"] {
      background: url("/assets/img/icons/telegram-gray.svg") no-repeat right;
      background-position-x: 6px;
      padding-left: 30px;
      padding-right: 30px;
    }

    &:not(:placeholder-shown) {
      &[name="telegram"] {
        background: url("/assets/img/icons/telegram-active.svg") no-repeat right;
        background-position-x: 6px;
      }
    }
  }

  input[type="number"]:not(input[id^="react-select"]) {
    -moz-appearance: textfield;
    width: 65px;
    float: right;
    margin-top: -20px;
    height: 36px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  button[type="submit"] {
    width: 100%;
    max-width: 232px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-color: $whiteColor;
    color: $blackColor;
    border-radius: 8px;
    box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
    border: none;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    height: max-content;

    &:hover {
      color: $whiteColor;
      background-color: $hoverColor;
    }
  }
}

.photo-skeleton {
  display: block;
  margin-top: 20px;
  margin-bottom: 25px;

  br {
    display: none;
  }
}
