@import "styles/variables.scss";

.container {
  padding: 0 0 12px 10px;
  display: grid;
  grid-template-columns: 272px;
  row-gap: 7px;
  height: max-content;
  margin-right: 10px;

  @media (min-width: 501px) and (max-width: 1020px) {
    padding: 0 6%;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &--open {
    max-height: 520px;
    row-gap: 14px;
  }
}
