@import "styles/variables.scss";

.container {
  display: block;
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px 15px;
  position: relative;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  height: max-content;
  min-height: 124px;

  &:hover {
    box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);

    .arrow {
      stroke: $activeColor;
    }
  }
}

.arrow {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  right: 6px;
  stroke: transparent;
  transition: stroke 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.text {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 24px;
  color: $blackColor;
  margin-right: auto;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.image {
  width: 52px;
  height: 52px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
