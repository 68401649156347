@import "styles/variables.scss";

.container {
  width: 100%;
  height: 70px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-between;
}

.project {
  display: flex;
  align-items: center;

  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 10px;
    object-fit: cover;
    transition: width 0.12s ease-out, height 0.12s ease-out;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__name {
    color: $blackColor;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  &__manager {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $greyColor;
  }
}

.details {
  display: flex;
  align-items: center;
  width: 59.5%;
  padding: 0 5%;
}

.progress_container {
  display: flex;
  align-items: center;
  width: 55%;
  margin-right: 30px;
}

.progress {
  position: relative;
  width: 81%;
  height: 4px;
  background-color: $backgroundGrey;
  border-radius: 3px;

  &--bar {
    height: 4px;
    border-radius: 10px;
    background: linear-gradient(0deg, #3dd598, #3dd598),
      linear-gradient(0deg, #4aa785, #4aa785), #00c2fa;
    margin-right: 10px;
  }

  &--text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $greyColor;
    margin-left: 15px;
    white-space: nowrap;
  }
}

.planned_hours {
  background-color: $backgroundBlue;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin-right: 40px;
  width: 18%;
  text-align: center;
  justify-content: center;

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $blueColor;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    margin-right: 5px;
  }
}

.employees {
  margin-right: 20px;
  display: flex;
  align-items: center;

  &__icon {
    fill: $greyColor;
    margin-right: 5px;
  }

  &__text {
    color: $greyColor;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}

.type {
  display: flex;
  align-items: center;
  width: 20%;

  &__icon {
    margin-right: 5px;

    path {
      fill: $greyColor;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
