@import "styles/variables.scss";

.card {
  height: 50px;
  padding: 6px 8px;
  border-bottom: 1px solid $greyColor3;
  border-left: 1px solid $greyColor3;
  border-right: 1px solid $greyColor3;
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background-color: $activeColor;
  object-fit: cover;
  object-position: center;
}
.info {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.infoName {
  overflow: hidden;
  color: $blackColor;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0px;
  white-space: nowrap;
  width: calc(100% - 10px);
}
.infoPosition {
  overflow: hidden;
  color: $darkGrey;
  font-feature-settings: "liga" off, "clig" off;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  white-space: nowrap;
  width: calc(100% - 10px);
}

.cardSmall {
  .info {
    display: none;
  }
}
