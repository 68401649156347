@import "styles/variables.scss";
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;

  & > p:last-child {
    display: none;
    font-weight: 300;
    font-size: 0.5rem;
    line-height: 10px;
    color: $errorColor;
    margin-top: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &[data-error] {
    input {
      color: $errorColor;
    }

    & > p:last-child {
      display: block;
    }
  }
}
.container-outline {
  align-items: flex-start;
  border-radius: 8px;
  label {
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    color: $darkGrey;
  }
}

.container-shadow {
  align-items: center;
  margin-left: 20px;
  width: 11%;
  border-radius: 4px;
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 11px;
  transition: stroke 0.2s ease-out, fill 0.2s ease-out;
  stroke: $blackColor;

  &--fill {
    fill: $blackColor;
    stroke: none;
  }
}

.error {
  width: 100%;
  text-align: inherit;
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 10px;
  margin-top: 2px;
  color: $errorColor2;
}
