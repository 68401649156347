.mainContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 30px;
  gap: 30px;
}

.container {
  width: 100%;
  padding: 30px 30px 0;
  height: 100%;
}
