.item {
  padding: 0px 16px;
  gap: 16px;
  border-radius: 8px;
  border: 2px solid #f2f2f2;
  background: #fff;
  transition: all 300ms ease;
}
.header {
  height: 40px;
  min-height: 40px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
  position: relative;
  cursor: pointer;
}
.titleWrap {
  align-items: center;
  display: flex;
  width: 100%;
  gap: 4px;
  input {
    box-shadow: unset;
    outline: none;
    border: none;
    text-align: left;
    font-weight: 500;
    font-size: 1rem;
    &:focus {
      outline: none;
    }
  }
}
.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: #181818;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 500;
}
.actions {
  display: flex;
  align-items: center;
  gap: 12px;
}
.btnEdit {
  svg path {
    fill: #e2e2e2;
    transition: fill 300ms ease;
  }
  @media (hover: hover) {
    &:hover {
      svg path {
        transition: fill 300ms ease;
        fill: #00c2fa;
      }
    }
  }
}
.btnDelete {
  svg path {
    fill: #e2e2e2;
    transition: fill 300ms ease;
  }
  @media (hover: hover) {
    &:hover {
      svg path {
        transition: fill 300ms ease;
        fill: #00c2fa;
      }
    }
  }
}

.body {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 500ms;
  border-top: 0px solid #f2f2f2;
  padding: 0px 0px;
  width: 90%;
  transition: all 300ms ease;
  &[aria-hidden="false"] {
    border-top: 1px solid #f2f2f2;
    grid-template-rows: 1fr;
    padding: 8px 0px;
    transition: all 300ms ease;
  }
  & > div {
    overflow: hidden;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 4px;
  input {
    box-shadow: unset;
    outline: none;
    border: none;
    text-align: left;
    font-weight: 500;
    font-size: 1rem;
    &:focus {
      outline: none;
    }
  }
}

.btnEditSave {
  right: -4px;
  position: absolute;
  top: 30px;
}

.active {
  background-color: #00c2fa;
  border: 2px solid #00c2fa;
  .title {
    color: #fff;
  }
}
