@import "styles/variables.scss";

.form {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 20px;
  position: relative;

  label {
    display: none;
  }

  section {
    &[data-error] {
      input {
        color: $errorColor;
        outline: 1px solid $errorColor2;
      }
    }
  }

  section:first-child {
    width: 40%;
    padding-left: 5px;

    input {
      width: 100%;
      caret-color: $blackColor;

      &:focus {
        color: $halfAccent;

        &::placeholder {
          color: $blackColor;
        }
      }

      &::placeholder {
        transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        color: $greyColor;
      }

      &:hover {
        &::placeholder {
          color: $blackColor;
        }
      }
    }
  }

  section:nth-child(2) {
    width: 10%;
    margin-left: 20px;
  }

  section:nth-child(3) {
    width: 10%;
    margin-left: 20px;
  }

  section:nth-child(4) {
    width: 10%;
    margin-left: 20px;
  }

  section:nth-child(5) {
    width: 10%;
    margin-left: 20px;
  }

  section:nth-child(6) {
    width: 10%;
    margin-left: 20px;
  }
}

.formBtn {
  width: 34px;
  height: 34px;
  position: absolute;
  top: -20px;
  right: -4px;
  cursor: pointer;
  border-radius: 25px;
  background: url("../../../../assets/img/icons/skills-check.svg");
  background-size: cover;
}
