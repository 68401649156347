@import "styles/variables.scss";

.container {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  height: max-content;
  max-height: calc(100vh - 260px);
  //overflow: visible auto;
}

.row {
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 1220px) {
    display: grid;
    grid-template-columns: 1fr 0.6fr 0.3fr 0.4fr 0.6fr 150px;
    align-items: center;
    column-gap: 20px;
  }
}
.group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;
  row-gap: 8px;
  &.pb8 {
    padding-bottom: 8px;
  }
}
.name {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 24px;
  color: $blackColor2;
}

.image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.position,
.competency,
.department,
.hours {
  font-weight: 500;
  font-size: 1rem;
  line-height: 17px;
  color: $blackColor2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.edit {
  display: flex;
  align-items: "center";
  gap: 8px;
  cursor: pointer;
  color: $darkGrey;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  @media (hover: hover) {
    &:hover {
      color: $activeColor;
      path {
        fill: $activeColor;
      }
    }
  }
}

.editIcon {
  width: 16px;
  height: 16px;
  path {
    fill: $darkGrey;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.editLabel {
  display: none;
  @media (min-width: 768px) {
    display: inline;
  }
}

.paddingPaper {
  padding: 16px 24px;
}

button.modalCloase {
  left: unset;
  right: 16px;
  top: 16px;
}

div.modal {
  max-width: 816px;
  width: 100%;
}
