@import "styles/variables.scss";

.paper {
  background-color: $backgroundWhite;
  box-shadow: 0px 0px 11px -2px $shadowColor;
  border-radius: 8px;
}

.paperPadding {
  padding: 4px;
  @media (min-width: 1020px) {
    padding: 16px 25px;
  }
}
