@import "styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  text-transform: uppercase;
  color: $greyColor;
  font-weight: 700;
  font-size: 1.25rem;
}
