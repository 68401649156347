@import "styles/variables.scss";

.table {
  margin-top: 16px;
  width: 100%;

  div {
    background: none;
    border: none;
  }
}

.header {
  display: flex;

  &__item {
    width: 33.3%;
    padding: 0;
    height: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__item:last-child {
    justify-content: flex-end;
  }

  &__item:first-child {
    justify-content: flex-start;
  }
}

.header p {
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.oneRow {
  display: flex;
  min-height: 47px;
  height: fit-content;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 33.33%;

  &:first-child {
    padding-left: 0;
    justify-content: flex-start;
  }

  &:last-child {
    font-weight: 700;
    justify-content: flex-end;
    color: $greyColor;
    text-align: right;
  }
}

.cellContainer {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: normal;
  width: max-content;

  p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:not(.cellContainerSimple):hover {
    color: $greyColor;
    cursor: pointer;

    p {
      color: $greyColor;
    }
  }
}
