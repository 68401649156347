.journal-item-editor .toastui-editor-contents {
  min-height: 60px;
}

.journal-item-editor .toastui-editor-md-preview {
  height: auto !important;
  margin-left: 11px;
}

.journal-item-editor .toastui-editor-md-splitter {
  left: 49.9% !important;
}
