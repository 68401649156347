@import "../../../styles/variables.scss";

.container {
  width: 315px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: auto;
}

.currentPage {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.input {
  width: 63px;
  height: 24px;
  margin-left: 6px;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  border: none;
  outline: none;
  text-align: center;

  @media (max-width: 650px) {
    width: 50px;
  }
}

.countPages {
  margin-left: 24px;
  color: $greyColor;

  @media (max-width: 650px) {
    margin-left: 12px;
  }
}

.arrows {
  margin-left: 6px;

  @media (max-width: 650px) {
    margin-left: 0;
  }
}

.arrow {
  height: 24px;
}
