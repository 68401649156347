@import "styles/variables.scss";

.container {
  padding: 20px 10px;
  display: grid;
  row-gap: 20px;
  justify-items: center;
  height: 100%;
}

.image {
  width: 110px;
  height: 110px;
  overflow: hidden;
  border-radius: 50%;

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.title {
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 28px;
  color: $totalBlackColor;
  max-width: 234px;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.info {
  width: 100%;
  display: grid;
  row-gap: 20px;

  @media (max-width: 500px) {
    padding: 0;
  }

  @media (min-width: 501px) and (max-width: 1020px) {
    padding: 0 12%;
  }

  &__item {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    color: $blackColor;
    max-width: 140px;
  }

  &__text {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    color: $greyColor;
    max-width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &--manager {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 16px;
      color: $greyColor;
      max-width: 130px;
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      text-align: right;
    }

    &--statuses {
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 16px;
      color: $greyColor;
      max-width: 130px;
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      text-align: right;
    }
  }
}

.link {
  width: 100%;
  max-width: 232px;
  height: max-content;
  text-align: center;
  padding: 8px;
  background-color: $whiteColor;
  color: $blackColor;
  border-radius: 8px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;

  &:hover {
    color: $whiteColor;
    background-color: $hoverColor;
  }
}
