@import "styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $blackColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  position: relative;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);
  }

  &--medSize {
    display: flex;
    flex-direction: row;
    box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);
    }
  }
}

.arrow {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 6px;
  right: 5px;
  cursor: pointer;
  stroke: $blackColor;
  transition: stroke 0.25s ease-out;

  &:hover {
    stroke: $activeColor;
  }

  &--medSize {
    display: none;
  }
}

.avatar {
  width: 85px;
  height: 85px;
  border-radius: 100px;
  object-fit: cover;
  margin-top: 10px;

  &--medSize {
    width: 54px;
    height: 54px;
    border-radius: 100px;
    object-fit: cover;
    margin: 14px;
  }
}

.mentor {
  margin-top: 12px;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;

  &--medSize {
    font-weight: 500;
    font-size: 20px;
    line-height: 18px;
    margin: 0 15px 0 0;
  }

  &--minSize {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    margin: 0;
  }
}

.name {
  margin-top: 5px;
  color: $greyColor;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  &--medSize {
    display: none;
  }
}

.speciality {
  display: flex;
  flex-direction: column;
  margin: 15px 10px 20px 10px;

  &--medSize {
    display: none;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 12px;
  }
}

.itemTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $blackColor;

  &--medSize {
    display: none;
  }
}

.itemValue {
  text-align: right;
  color: $greyColor;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  max-width: 145px;

  &--medSize {
    display: none;
  }
}

.minContainer {
  width: 189px;
  height: 36px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  color: $blackColor;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  cursor: pointer;
}

.skeleton-container {
  display: block;
  width: 100%;
  height: 285px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 11px;

  br {
    display: none;
  }
}
