@import "styles/variables.scss";

.radio {
  display: none;
}

.tab {
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  color: $greyColor4;
  background-color: $backgroundGrey;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    border 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  width: max-content;
  height: 40px;
  padding: 10px 20px;
  border: 1px solid $greyColor3;

  &:hover {
    border-color: $activeColor;
  }
}

.container {
  input:checked + label {
    background-color: $activeColor;
    color: $whiteColor;
    border-color: $activeColor;
  }

  input:disabled + label {
    background-color: $backgroundGrey;
    color: $greyColor4;
    border-color: #e2e2e2;
    opacity: 0.5;
  }
}
