@import "styles/variables.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &--minSize {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  &__title {
    color: $blueColor;
    font-weight: 500;
    font-size: 1rem;
    line-height: 16px;
    margin-top: -6px;
    margin-left: -14px;
  }
}

.cross {
  position: absolute;
  left: -14px;
  top: -14px;

  &__icon {
    width: 20px;
    height: 20px;
    fill: $blackColor;
    transition: fill 0.25s ease-out;
  }

  &:hover {
    .cross__icon {
      fill: $activeColor;
    }
  }
}

.calendars {
  padding: 20px;
  display: none;
  width: 100%;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);
  }

  &--open {
    display: block;
  }
}

.startVacation {
  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $greyColor;
  }
}

.endVacation {
  margin-top: 48px;

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $greyColor;
  }
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $greyColor;
    margin-left: 10px;
  }

  section {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 20px;
  }

  div {
    box-shadow: none;
  }
}

.formBtn {
  width: 100%;
  height: 36px;
  margin-top: 31px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: background 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: rgba(0, 194, 250, 0.6);
    color: $whiteColor;
  }

  &:active {
    background-color: $activeColor;
  }
}
