.container {
  display: grid;
  grid-template-columns: 292px minmax(552px, 100%);
  gap: 30px;
  width: 100%;

  @media (max-width: 1020px) {
    display: flex;
    flex-direction: column;
  }
}
