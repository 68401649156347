@import "styles/variables.scss";

.column {
  display: flex;
  flex-direction: column;
}

.cell {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.delete {
  opacity: 0;
  margin: -4px 0 0 -5px;
  transition: margin 0.3s cubic-bezier(1, 0, 0.2, 0.4),
    opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  width: 20px;
  height: 20px;
}

.name {
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  color: $blackColor;

  &:hover {
    color: $greyColor;
    cursor: pointer;

    .delete {
      opacity: 1;
      margin: -4px 0 0 2px;
      fill: $greyColor;

      &:hover {
        fill: $errorColor2;
      }
    }
  }
}

.edit {
  opacity: 0;
  margin: -2px 0 0 -5px;
  transition: margin 0.3s cubic-bezier(1, 0, 0.2, 0.4),
    opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  width: 16px;
  height: 16px;

  & path {
    fill: $greyColor;
  }
}

.hours {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 17px;
  color: $greyColor;

  &:hover {
    color: $greyColor;

    .edit {
      opacity: 1;
      margin: -2px 0 0 6px;
    }
  }
}

.form-button {
  margin-top: auto;
  width: 100%;
  text-align: center;
  padding: 5px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 17px;
  background: $whiteColor;
  border: 1px dashed $greyColor3;
  border-radius: 8px;
  transition: border 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  color: $blackColor2;

  &:hover {
    border: 1px dashed $activeColor;
  }
}

.inputContainer section {
  width: 100%;
}

.form {
  width: 100%;
  max-width: 260px;
  display: grid;
  grid-template-columns: minmax(110px, 240px);
  row-gap: 10px;
  margin-top: auto;
  padding: 10px 8px;
  border-radius: 8px;
  border: 1px dashed $activeColor;

  section {
    width: 100%;
    margin: 0;
    height: 36px;

    input {
      height: inherit;
      font-size: 1rem;
      line-height: 19px;
      text-align: left;
      padding: 0 10px;
      width: 100%;

      &[name="hours"] {
        background: url("/assets/img/icons/clock.svg") no-repeat right;
        background-position-x: 8px;
        padding-left: 36px;
      }
    }
  }
}

.formBtn {
  width: max-content;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 17px;
  background: $whiteColor;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  color: $blackColor2;

  &:hover {
    color: $hoverColor;
  }
}

.close {
  width: max-content;
  margin: 0 auto;

  svg {
    width: 16px;
    height: 16px;

    circle {
      fill: $greyColor3;
      transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    &:hover {
      circle {
        fill: $errorColor2;
      }
    }
  }
}

.skeleton {
  margin-top: auto;
  padding: 10px 8px;
  border-radius: 8px;
  border: 1px dashed $greyColor;

  span {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    &:nth-of-type(1),
    &:nth-of-type(2) {
      height: 36px;
    }
  }

  br {
    display: none;
  }
}

.success {
  min-width: 210px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 16px;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1;

  svg {
    width: 40px;
    height: 40px;
  }
}
