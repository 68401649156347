@import "styles/variables.scss";

.burger__icon {
  transition: width 0.2s linear, height 0.2s linear;
  width: 32px;
  height: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &:hover {
    width: 36px;
    height: 19px;

    .burger__line {
      background-color: $hoverColor;
      height: 2.52px;
    }

    &:active {
      .burger__line {
        background-color: $blueColor;
        height: 2.52px;
      }
    }
  }

  &--open {
    transform: rotate(90deg);
    transform-origin: 75% 100%;
    width: 36px;
    height: 14px;

    .burger__line {
      height: 2.52px;
      background-color: $greyColor;

      &:nth-child(3) {
        width: 50%;
      }
    }

    &:hover {
      width: 30px;
      height: 12px;

      .burger__line {
        background-color: $hoverColor;
        height: 2.12px;
      }
    }
  }
}

.burger__line {
  width: 100%;
  background-color: $blackColor;
  transition: height 0.25s linear, background-color 0.25s ease-out;
  height: 2.2px;

  &:nth-child(2) {
    width: 75%;
  }
}
