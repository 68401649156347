@import "styles/variables.scss";

.container {
  width: 100%;
  position: relative;
}

.search {
  padding: 0 10px;
  margin-bottom: 38px;
}

.members-container {
  max-height: 434px;
  row-gap: 14px;
}

.help-text {
  text-align: center;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 14px;
  color: $greyColor;
}

.error {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 20px;
  margin-top: 10px;
  color: $errorColor2;
}

.popup {
  position: absolute;
  padding: 20px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  background: #ff76760c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 11px;
  cursor: pointer;
}
