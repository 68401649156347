@import "./variables.scss";

.editor-global {
  .toastui-editor-defaultUI {
    border: none;
  }

  .toastui-editor-contents::-webkit-scrollbar,
  .ProseMirror::-webkit-scrollbar,
  .toastui-editor-md-preview::-webkit-scrollbar {
    width: 4px;
  }

  .toastui-editor-contents a {
    color: $blackColor;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .toastui-editor-contents a:hover {
    color: $halfAccent;
  }

  .toastui-editor-contents del {
    color: $greyColor;
  }

  .toastui-editor-defaultUI-toolbar {
    padding: 0;
    height: auto;
    background: $whiteColor;
    border: none;
  }

  .toastui-editor-defaultUI p {
    background: $whiteColor;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 24px;
    color: $blackColor;
  }

  .toastui-editor-defaultUI {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      background: $whiteColor;
      font-family: "Montserrat";
      font-style: normal;
      line-height: 24px;
      color: $blackColor;
      margin-top: 0 !important;
    }
  }

  .placeholder.ProseMirror-widget {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 300;
    font-size: 1rem;
    line-height: 24px;
    color: $greyColor;
  }

  .ProseMirror.toastui-editor-contents {
    padding: 10px;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
  }

  .toastui-editor-mode-switch {
    background: $whiteColor;
    border: none;
    padding-right: 5px;
    height: auto;
    padding-top: 10px;
  }

  .toastui-editor-mode-switch .tab-item {
    background: $whiteColor;
    color: $greyColor;
    margin-top: 0;
    margin-right: 0;
    border: none;
    font-weight: 600;
    font-size: 0.75rem;
    width: 76px;
    height: auto;
    box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .toastui-editor-mode-switch .tab-item:hover {
    color: $halfAccent;
    box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.16);
  }

  .toastui-editor-mode-switch .tab-item:not(:last-child) {
    margin-right: 5px;
  }

  .toastui-editor-mode-switch .tab-item.active {
    border: none;
    background-color: $whiteColor;
    color: $blackColor;
  }

  .toastui-editor-toolbar {
    height: auto;
    margin-bottom: 15px;
  }

  .toastui-editor-toolbar-group {
    gap: 10px;

    button {
      box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      border: 1px solid $whiteColor;

      &:not(:disabled):hover {
        border: 1px solid $whiteColor;
        background-color: $whiteColor;
        box-shadow: 0px 0px 19px -2px rgba(0, 0, 0, 0.16);
      }
    }
  }

  .toastui-editor-toolbar-icons {
    margin: 0;
  }

  .ProseMirror,
  .toastui-editor-md-preview {
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    height: 100%;
  }

  .toastui-editor-tooltip {
    background: $greyColor;

    .arrow {
      background: $greyColor;
    }

    .text {
      color: $whiteColor;
    }
  }

  .toastui-editor-popup {
    border-radius: 8px;

    button,
    input,
    .toastui-editor-file-name,
    #toastuiImageUrlInput,
    #toastuiAltTextInput {
      border-radius: 4px;
    }
  }

  .toastui-editor-popup-add-image .toastui-editor-file-select-button {
    height: 30px;
  }

  .toastui-editor-popup-add-heading h1,
  .toastui-editor-popup-add-heading h2,
  .toastui-editor-popup-add-heading h3,
  .toastui-editor-popup-add-heading h4,
  .toastui-editor-popup-add-heading h5,
  .toastui-editor-popup-add-heading h6 {
    background: transparent;
  }

  .toastui-editor-popup-add-image .toastui-editor-tabs .tab-item.active {
    color: $blueColor;
    border-bottom: 2px solid $blueColor;
  }

  .toastui-editor-defaultUI .toastui-editor-ok-button {
    background-color: $halfAccent;
    outline-color: $halfAccent;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      outline-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      background-color: $blueColor;
      outline-color: $blueColor;
    }
  }

  .toastui-editor-toolbar-icons {
    &:hover {
      background-position-y: -75px;
    }
  }
}
