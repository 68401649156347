@import "styles/variables.scss";

.header-planning-employee {
  width: 92%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2%;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: $whiteColor;

  .user {
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin-right: 10px;
      object-fit: cover;
      transition: width 0.12s ease-out, height 0.12s ease-out;
    }

    .user-info {
      display: flex;
      flex-direction: column;

      .user-name {
        color: $blackColor;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
      }

      .user-position {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $greyColor;
      }
    }
  }

  .progress-container {
    display: flex;
    align-items: center;
    width: 27%;
  }

  .progress {
    position: relative;
    width: 91%;
    height: 4px;
    background-color: $greyColor;
    border-radius: 3px;

    &--bar {
      height: 4px;
      border-radius: 10px;
      background-color: $blueColor;
      margin-right: 10px;
    }

    &--text {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $blackColor;
      margin-left: 15px;
    }
  }

  .projects {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $backgroundPurple;
    padding: 6px 10px;
    border-radius: 5px;

    &--display {
      margin-right: 10px;
    }

    &--text {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: $purpleColor;
      display: flex;
    }

    &--number {
      color: $purpleColor;
      margin-left: 5px;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.dates {
  display: flex;
  align-items: center;
  width: 19%;

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }
}

.calendar {
  margin-right: 10px;
  cursor: pointer;
}

.selectContainer {
  width: 100%;
}
