.editor-preview .toastui-editor-main p:not([class]),
.editor-preview .toastui-editor-main h1:not([class]),
.editor-preview .toastui-editor-main h2:not([class]),
.editor-preview .toastui-editor-main h3:not([class]),
.editor-preview .toastui-editor-main h4:not([class]),
.editor-preview .toastui-editor-main h5:not([class]),
.editor-preview .toastui-editor-main h6:not([class]) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.editor-preview--open .toastui-editor-main p:not([class]),
.editor-preview--open .toastui-editor-main h1:not([class]),
.editor-preview--open .toastui-editor-main h2:not([class]),
.editor-preview--open .toastui-editor-main h3:not([class]),
.editor-preview--open .toastui-editor-main h4:not([class]),
.editor-preview--open .toastui-editor-main h5:not([class]),
.editor-preview--open .toastui-editor-main h6:not([class]) {
  -webkit-line-clamp: inherit;
}

.editor-preview .toastui-editor-contents {
  overflow: hidden;
  height: 24px;
}

.editor-preview--open .toastui-editor-contents {
  overflow: auto;
  height: auto;
}

.editor-preview .ProseMirror.toastui-editor-contents {
  padding: 0 !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.editor-preview .toastui-editor-toolbar {
  margin: 0 !important;
  display: none !important;
}

.editor-preview .toastui-editor {
  min-height: auto !important;
}

.editor-preview .toastui-editor-defaultUI {
  border: none;
}

.editor-preview .toastui-editor-defaultUI h1,
.editor-preview .toastui-editor-defaultUI h2,
.editor-preview .toastui-editor-defaultUI h3,
.editor-preview .toastui-editor-defaultUI h4,
.editor-preview .toastui-editor-defaultUI h5,
.editor-preview .toastui-editor-defaultUI h6 {
  margin-top: 0 !important;
}

.editor-preview:not(.editor-preview--open) .toastui-editor.ww-mode {
  height: 24px;
  overflow: hidden;
}
