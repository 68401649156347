@import "styles/variables.scss";

section.dashboarHeaderActionsSelect {
  width: auto;
  margin-left: 0px;
}

.dashboarHeaderActionsCalendarContent {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
}

.dashboarHeaderActionsCalendar {
  position: relative;
}

.dashboarHeaderActionsCalendarTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: color 300ms ease;
  min-width: 120px;
}

.dashboarHeaderActionsCalendarTitleIcon {
  flex: 0 0 17px;

  path {
    stroke: $darkGrey;
  }
}

.dashboarHeaderActionsCalendarTitleText {
  color: $darkGrey;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 16px;
  font-weight: 500;
  flex: 1 1 100%;
  text-align: center;
}

.arrowIconLeft,
.arrowIconRight {
  width: 17px;
  height: 17px;

  rect,
  path {
    color: $darkGrey;
  }
}

.arrowIconRight {
  transform: rotate(180deg);
}

.dashboarCalendar {
  position: absolute;
  top: 40px;
  transform: translateX(-30px);
  transition: all 300ms ease;
  min-width: 250px;
}

.dashboarCalendarOpen {
  opacity: 1;
  visibility: visible;
  z-index: 10;
}

.dashboarCalendarClose {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}

.dashboarHeaderActionsCalendarOpen {
  color: $blueColor;
}

.rangeType {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-radius: 8px;
  background-color: rgba(153, 153, 153, 0.2);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 32px;
}

.rangeTypeItem {
  color: #999;
  background-color: transparent;
  border-radius: 8px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background-color: #e2e2e2;
    color: #313131;
  }
}

.calendar {
  :global(.react-datepicker__day--selected),
  :global(.react-datepicker__day--in-selecting-range),
  :global(.react-datepicker__day--in-range) {
    background-color: transparent;
    color: inherit;
  }

  :global(.react-datepicker__month-text--in-range),
  :global(.react-datepicker__month-text--selected),
  :global(.react-datepicker__month-text--in-selecting-range),
  :global(.react-datepicker__year-text--selected),
  :global(.react-datepicker__year-text--in-selecting-range),
  :global(.react-datepicker__year-text--in-range) {
    background-color: $blueColor;
    color: #fff;
  }

  :global(.react-datepicker__year-text--selected) {
    background-color: $blueColor !important;
    color: #fff !important;
  }

  :global(.react-datepicker__day--today) {
    background-color: transparent;
  }

  :global(
      .react-datepicker__day--range-start.react-datepicker__day--in-range,

    ) {
    border: 1px solid $blueColor;
    background-color: transparent;
    border-radius: 20px 0px 0px 20px;
    border-right: none;
    color: #000;
  }

  :global(.react-datepicker__day--range-end.react-datepicker__day--in-range) {
    background-color: transparent;
    border: 1px solid $blueColor;
    background-color: transparent;
    border-radius: 0px 20px 20px 0px;
    border-left: none;
    color: $blackColor;
  }

  :global(.react-datepicker__day--in-range) {
    background-color: transparent;
    border: 1px solid $blueColor;
    border-left: none;
    border-right: none;
    color: $blackColor;
    border-radius: 0px;
  }

  :global(.react-datepicker__day--in-range:not([aria-disabled="true"]):hover) {
    background-color: transparent;
  }
  
  :global(.react-datepicker__day) {
    margin: 0;
    width: 32px;
    height: 32px;
  }
}
