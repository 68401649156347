@import "styles/variables.scss";

.container {
  width: 100%;
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &--minSize {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.calendar {
  padding: 20px;
  display: none;
  width: 100%;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);
  }

  &--open {
    display: block;
    position: relative;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  &__title {
    color: $blueColor;
    font-weight: 500;
    font-size: 1rem;
    line-height: 16px;
    margin-top: -6px;
    margin-left: -14px;
  }
}

.cross {
  position: absolute;
  left: -14px;
  top: -14px;

  &__icon {
    width: 20px;
    height: 20px;
    fill: $blackColor;
    transition: fill 0.25s ease-out;
  }

  &:hover {
    .cross__icon {
      fill: $activeColor;
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $greyColor;
    margin-left: 10px;
    display: block !important;
  }

  section {
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 0px;
  }

  div {
    box-shadow: none;
  }

  input {
    width: 100%;
    height: 36px;
    text-align: left;
    padding-left: 10px;
    margin-top: 20px;
    color: $blueColor;
    caret-color: $blackColor;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    text-overflow: ellipsis;

    &:focus {
      color: $halfAccent;

      &::placeholder {
        color: $blackColor;
      }
    }

    &::placeholder {
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      color: $greyColor;
    }

    &:hover {
      &::placeholder {
        color: $blackColor;
      }
    }
  }

  input[type="time"] {
    display: none;
  }

  section:nth-child(2) {
    display: none;
  }

  section:nth-child(3) {
    display: none;
  }

  section:nth-child(4) {
    margin-top: 30px;
  }

  textarea {
    margin-top: 10px;
    caret-color: $blackColor;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: $blueColor;

    &:focus {
      color: $halfAccent;

      &::placeholder {
        color: $blackColor;
      }
    }

    &::placeholder {
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      color: $greyColor;
    }

    &:hover {
      &::placeholder {
        color: $blackColor;
      }
    }
  }
}

.formTimeInputsOpen {
  display: flex;
  flex-direction: column;
  position: relative;

  label {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $greyColor;
    margin-left: 10px;
    display: block !important;
  }

  section {
    width: 100% !important;
    margin-left: 0 !important;
    margin-top: 0px;
  }

  section:nth-child(2) {
    margin-top: 30px;
    width: 35% !important;

    input {
      text-align: center;
      padding: 0;
    }

    label {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $blueColor;
      position: absolute;
      top: 345px;
      left: -10px;
      width: 35%;
      text-align: center;
    }
  }

  section:nth-child(3) {
    width: 35% !important;
    position: absolute;
    top: 285px;
    left: 100px;

    input {
      text-align: center;
      padding: 0;
    }

    label {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $blueColor;
      position: absolute;
      top: 61px;
      text-align: center;
    }
  }

  section:nth-child(4) {
    margin-top: 15px;
  }

  div {
    box-shadow: none;
  }

  input {
    width: 100%;
    height: 36px;
    text-align: left;
    padding-left: 10px;
    margin-top: 20px;
    color: $blueColor;
    caret-color: $blackColor;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    text-overflow: ellipsis;

    &:focus {
      color: $halfAccent;

      &::placeholder {
        color: $blackColor;
      }
    }

    &::placeholder {
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      color: $greyColor;
    }

    &:hover {
      &::placeholder {
        color: $blackColor;
      }
    }
  }

  textarea {
    margin-top: 10px;
    caret-color: $blackColor;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: $blueColor;

    &:focus {
      color: $halfAccent;

      &::placeholder {
        color: $blackColor;
      }
    }

    &::placeholder {
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      color: $greyColor;
    }

    &:hover {
      &::placeholder {
        color: $blackColor;
      }
    }
  }
}

.formBtn {
  width: 100%;
  height: 36px;
  margin-top: 33px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: background 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background-color: rgba(0, 194, 250, 0.6);
    color: $whiteColor;
  }

  &:active {
    background-color: $activeColor;
  }
}

.btnChooseHours {
  position: absolute;
  top: 320px;
  text-align: center;
  width: 86%;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $greyColor;
  z-index: 2;

  &:hover {
    color: $hoverColor;
  }

  &:active {
    color: $activeColor;
  }
}

.hoursBtns {
  position: absolute;
  display: flex;
  z-index: 2;
  top: 363px;
  right: 7px;
}

.hoursBtnsHidden {
  display: none;
}

.icon {
  width: 23px;
  height: 23px;
  margin-right: 10px;
  cursor: pointer;
}

.error {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 20px;
  margin-top: 10px;
  color: $errorColor2;
}

.popup {
  position: absolute;
  padding: 20px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  background: #ff76760c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 11px;
  cursor: pointer;
  z-index: 3;
}
