@import "styles/variables.scss";

.userName {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  margin-top: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
}

.job {
  margin-top: 5px;
  color: $greyColor;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  margin-top: 6px;
  width: 100%;
  height: 100%;
  min-height: 272px;

  label {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $greyColor;
    margin: 0;
  }

  input {
    width: 100%;
    height: 36px;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    text-align: left;
    padding: 0 20px;

    &::-webkit-input-placeholder {
      color: $greyColor;
    }
  }

  input:hover {
    &::-webkit-input-placeholder {
      color: $blackColor;
    }
  }

  section label + input[type="password"] {
    margin-top: 0;
  }

  section {
    width: 100%;
    margin-left: 0;

    &:first-child {
      margin-bottom: 18px;
    }

    &:nth-child(2) {
      margin-bottom: 14px;
    }

    &:nth-child(5) input {
      margin-top: 6px;
    }

    &:not(:nth-child(2)):not(:first-child) {
      margin-bottom: 14px;
    }

    &[data-error] input {
      outline: 1px solid $errorColor2;
    }
  }

  input[name="email"],
  input[name="tel"],
  input[name="telegram"],
  label[for="id_birthday"] + div {
    display: none;
  }
}

.formBtn {
  width: 172px;
  height: 36px;
  margin-top: auto;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: $whiteColor;
  color: $blackColor;

  &:hover {
    color: $whiteColor;
    background-color: $hoverColor;
  }

  &:active {
    background: $activeColor;
  }
}

.formAvatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin-top: -12px;
  width: 100%;

  input[type="file"] + label {
    width: 98px;
    height: 98px;
    position: relative;
    cursor: pointer;
    margin-right: 0;

    &:hover {
      & > svg {
        width: 22px;
        height: 22px;

        circle {
          fill: $activeColor;
        }
      }
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    & > svg {
      width: 27.5px;
      height: 27.5px;
      position: absolute;
      right: 0;
      bottom: -4px;
      transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1),
        height 0.3s cubic-bezier(0.4, 0, 0.2, 1);

      circle {
        fill: $hoverColor;
        transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      }

      path {
        fill: $whiteColor;
      }
    }
  }
}

.contacts {
  margin-top: 20px;
  width: 100%;
  padding: 0 10px;

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 14px;
    }
  }

  &__text {
    color: $greyColor;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    transition: color 0.2s ease-out;
  }

  a:hover {
    .contacts__text {
      color: $hoverColor;
    }

    .icon {
      stroke: $hoverColor;
    }

    .icon--fill {
      stroke: none;
      fill: $hoverColor;
    }
  }
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 11px;
  transition: stroke 0.2s ease-out, fill 0.2s ease-out;
  stroke: $blackColor;

  &--fill {
    fill: $blackColor;
    stroke: none;
  }
}

.avatat-skeleton {
  margin-top: -12px;

  br {
    display: none;
  }
}

.password-skeleton {
  height: 272px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;

  span:last-of-type {
    margin-top: auto !important;
  }
}
