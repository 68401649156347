// COLORS
$whiteColor: #ffffff;
$blackColor: #181818;
$blackColor2: #3d3c3c;
$totalBlackColor: #000000;
$greyColor: #b6b6b6;
$greyColor2: #858585;
$greyColor3: #e2e2e2;
$greyColor4: #313131;
$darkGrey: #999999;
$blueColor: #00c2fa;
$halfAccent: #66dafc;
$tableGradientBlue: #8ce6ff;
$tableBlue: #d1f4ff;
$errorColor: #ff5656;
$errorColor2: #ff7676;
$btnReports: rgba(0, 194, 250, 0.6);
$purpleColor: #8a8cd9;
$orangeColor: #ff974a;
$backgroundBlue: #e2f5ff;
$backgroundGrey: #f2f2f2;
$backgroundPurple: linear-gradient(0deg, #ededff, #ededff), #e5ecf6;
$backgroundWhite: #fff;
$shadowColor: rgba(0, 0, 0, 0.1);

$statusTextVacation: #52b479;
$statusColorVacation: #52b47933;
$statusTextMedical: rgba(0, 194, 250, 1);
$statusColorMedical: rgba(0, 194, 250, 0.2);
$statusTextTruancy: #ff5656;
$statusColorTruancy: rgba(255, 118, 118, 0.2);
// STATE COLORS
$hoverColor: #66dafc;
$activeColor: #00c2fa;
