@import "styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1 0 200px;
}

.label {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: $blackColor;
  margin-bottom: 7px;
}

.items {
  display: flex;
  flex-direction: column;
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0 8px;
}

.item {
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: $blackColor;
  border-bottom: 1px solid $backgroundGrey;
  margin-bottom: 10px;

  &:last-child {
    height: 110px;
    margin-bottom: 0px;
    border-bottom: none;
  }
}
