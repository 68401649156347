@import "styles/variables.scss";

.container {
  display: grid;
  row-gap: 10px;

  @media (max-width: 460px) {
    display: block;
  }
}

.details-container {
  position: relative;
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);
  }
}

.redact-controls {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 6px 0 6px;
  z-index: 1;
}

.redact {
  margin-left: auto;

  &:hover {
    .redact__icon {
      fill: $hoverColor;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    fill: $blackColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.cross {
  &:hover {
    .cross__icon {
      fill: $hoverColor;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    fill: $blackColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.members-container {
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: 292px;
  display: flex;
  flex-direction: column;

  @media (max-width: 460px) {
    margin-top: 30px;
  }

  @media (max-width: 1020px) {
    height: fit-content;
    padding-bottom: 30px;
  }

  &:hover {
    box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);
  }
}

.controls {
  display: flex;
  align-items: center;
  padding: 0 6px;
  margin-bottom: 10px;
}

.members--open {
  height: 585px;

  @media (max-width: 1020px) {
    height: fit-content;
  }

  .arrow__button {
    align-self: center;
    margin-top: auto;
    margin-bottom: 0;
  }
}

.arrow__button {
  margin-bottom: 10px;

  &:hover {
    .arrow__icon {
      fill: $hoverColor;
    }
  }
}

.arrow__icon {
  height: 7.12px;
  width: 15.37px;
  fill: $blackColor;
  transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  transform: rotate(180deg);
}

.controls-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 48px);
  margin-left: auto;

  &--full {
    width: 100%;
  }

  &__title {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    color: $totalBlackColor;
  }
}

.controls-redact {
  &__button {
    width: max-content;
    height: max-content;
    margin-top: 4px;
    margin-bottom: auto;

    &:hover {
      .controls-redact__icon {
        fill: $hoverColor;
      }
    }
  }

  &__icon {
    height: 24px;
    width: 24px;
    fill: $greyColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.close-members {
  width: max-content;
  height: 26px;

  &:hover {
    .close-members__icon {
      fill: $hoverColor;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    fill: $blackColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.members-min__button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  background-color: $whiteColor;
  color: $blackColor;
  border-radius: 8px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border: none;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 500;
  font-size: 1rem;
  line-height: 18px;
  height: max-content;

  @media (max-width: 460px) {
    margin-top: 20px;
  }

  &:hover {
    color: $whiteColor;
    background-color: $hoverColor;
  }
}

.members-redact {
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  height: 585px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1020px) {
    height: fit-content;
  }

  &:hover {
    box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 6px 0 6px;
    margin-bottom: 18px;
    width: 100%;
  }

  &__title {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    color: $totalBlackColor;
  }

  .arrow__button {
    margin-bottom: 0;
    margin-top: auto;
    width: max-content;
  }
}

.check {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .check__icon {
      ellipse {
        fill: $blueColor;
      }
    }
  }

  &__icon {
    width: 21px;
    height: 21px;
    display: block;

    ellipse {
      fill: $halfAccent;
      transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}
