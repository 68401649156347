@import "styles/variables.scss";

.container {
  width: 100%;
  background: $whiteColor;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width: 1920px) {
    max-width: 87.5vw;
  }
}
