@import "styles/variables.scss";

.container {
  padding: 20px;
  display: flex;
  justify-content: center;
  gap: 40px;
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0px 0px;
}

.planned {
  background-color: $backgroundBlue;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;

  &__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $blueColor;
  }

  &__value {
    margin-left: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $blueColor;
  }
}

.unassigned {
  background-color: $backgroundGrey;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $darkGrey;
  }

  &__value {
    margin-left: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $darkGrey;
  }
}

.projects {
  background: $backgroundPurple;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;

  &__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $purpleColor;
  }

  &__value {
    margin-left: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: $purpleColor;
  }
}

.icon {
  margin-right: 5px;
}
