@import "styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: grid;
  grid-template-columns: 330px 1fr;
  gap: 20px;
  margin-bottom: 10px;
}

.table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  row-gap: 20px;
  height: max-content;
  overflow-y: auto;
  margin: 0 -10px 15px -20px;
  padding: 10px 10px 4px 20px;
}

.skeleton {
  margin-bottom: 10px;
}
