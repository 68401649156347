@import "styles/variables.scss";

.tabel {
  width: 100%;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: unset;
  td {
    border: 1px solid $greyColor3;
    background: $whiteColor;
    padding: 0px 10px;
    color: $darkGrey;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 1rem;
    font-weight: 500;
    width: 170px;
    min-width: 170px;
  }
  th {
    background: $backgroundGrey;
    border: 1px solid $greyColor3;
    padding: 0px 10px;
    color: $greyColor4;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 1rem;
    font-weight: 500;
    width: 170px;
    min-width: 170px;
    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }
  }
  tr {
    min-height: 50px;
    height: 50px;
    white-space: nowrap;
    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}
.loader,
.loaderTr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 0px;
  svg {
    width: 120px;
    height: 120px;
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 0px;
}

.tdHover {
  cursor: pointer;
  &:hover {
    color: $blueColor;
  }
}
