@import "styles/variables.scss";

.container {
  display: grid;
  grid-template-columns: 1fr max-content;
  column-gap: 30px;
  margin-bottom: 30px;
  padding: 15px 20px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.selects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(210px, max-content));
  column-gap: 30px;

  section {
    margin: 0;
    width: 100%;
    height: 40px;

    &:hover {
      [class$="-indicatorContainer"] svg {
        fill: $hoverColor;
      }
    }
  }
}

button.calendar {
  margin-top: -3px;
  height: 46px;
  width: 290px;
  box-shadow: none;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.button,
.buttonPlus {
  width: max-content;
  padding: 8px 24px;
}

.buttonPlus svg {
  circle {
    fill: $whiteColor;
  }

  path {
    stroke: $activeColor;
  }
}
