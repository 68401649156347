@import "./variables.scss";

.planning-filters {
  .css-1nuor9e-control {
    box-shadow: none;
    outline: 2px solid transparent;
    transition: outline 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      outline: 2px solid $hoverColor;
    }
  }

  .css-tj5bde-Svg {
    fill: $greyColor3;
  }

  .css-1hs275a-ValueContainer,
  .css-fyq6mk-container,
  .css-1nuor9e-control,
  .css-11z2ey7-Input,
  .css-1oq8wir-control {
    height: 100%;
  }

  .css-1oq8wir-control {
    outline: 2px solid $activeColor;

    .css-h3jysl-IndicatorsContainer svg {
      fill: $activeColor !important;
    }
  }

  .css-fyq6mk-container {
    [class$="-placeholder"],
    [class$="-singleValue"],
    .css-7dplb8-Input,
    .css-11z2ey7-Input {
      font-weight: 500;
      font-size: 1rem;
      line-height: 20px;
    }
  }

  .css-11ys1wa-indicatorContainer,
  .css-1gv7fyq-indicatorContainer {
    height: 40px;
    top: 3px;
  }
}

.planning-form {
  .css-h3jysl-IndicatorsContainer {
    display: none;
  }

  .css-1hs275a-ValueContainer,
  .css-fyq6mk-container,
  .css-1nuor9e-control,
  .css-11z2ey7-Input,
  .css-1oq8wir-control {
    height: 100%;
  }

  .css-1hs275a-ValueContainer {
    justify-content: flex-start;

    &::before {
      content: url("/assets/img/icons/display.svg");
      width: 20px;
      height: 20px;
      grid-area: 1/1/2/2;
    }
  }

  .css-7dplb8-Input {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .css-12bxb0z-placeholder,
  .css-11z2ey7-Input,
  .css-7dplb8-Input,
  .css-m5vsg3-singleValue {
    grid-area: 1/2/2/3;
  }

  .css-fyq6mk-container {
    [class$="-placeholder"],
    [class$="-singleValue"],
    .css-7dplb8-Input,
    .css-11z2ey7-Input {
      font-size: 1rem;
      line-height: 19px;
      margin-left: 6px;
    }
  }
}
