@import "styles/variables.scss";

.statusList {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: clamp(8px, 2.1vw, 40px);
}

.statusItem {
  height: 24px;
  padding: 0px 4px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Vacation {
  color: $statusTextVacation;
  background-color: $statusColorVacation;
}
.Medical {
  color: $statusTextMedical;
  background-color: $statusColorMedical;
}
.Truancy {
  color: $statusTextTruancy;
  background-color: $statusColorTruancy;
}
