@import "styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  section {
    margin-left: 0px;
  }
}

.taskContainer {
  border-radius: 8px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.task {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  section {
    width: 80%;
    margin-top: 5px;
  }

  &__btn {
    &__icon {
      height: 20px;
      width: 20px;
      fill: $blackColor;
      transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);

      &:hover {
        fill: $hoverColor;
      }
    }

    &--open {
      transform: rotate(180deg);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;

    &__project {
      font-size: 1.12rem;
      font-weight: 600;
    }

    &__date {
      color: $greyColor;
      font-size: 0.75rem;
      font-weight: 500;
      margin-top: 5px;
    }
  }
}

.crossContainer {
  margin-bottom: 20px;

  &__icon {
    cursor: pointer;
  }
}

.deleteIcon {
  fill: $greyColor;
  cursor: pointer;
}

.editTask {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    color: $greyColor3;
    font-size: 0.75rem;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 10px;
  }

  section {
    width: 100%;
    align-items: flex-start;
  }
}

.inputContainer,
.dateContainer {
  width: 100%;

  section {
    width: 100%;
  }
}

.addBtn {
  background-color: $blueColor;
  color: $whiteColor;
  width: 87%;
  margin-bottom: 20px;
  margin-top: 30px;
}

input {
  width: 100%;
}

.okBtn {
  width: 34px;
  height: 34px;
  cursor: pointer;
  border-radius: 25px;
  background: url("../../../../assets/img/icons/skills-check.svg");
  background-size: cover;
  margin-top: 20px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  padding: 25px 20px;
  margin-bottom: 20px;
  align-items: flex-end;

  &__header {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    width: 100%;

    section {
      width: 130px !important;
    }

    p {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  section {
    width: 100%;
    margin-bottom: 10px;
  }
}

.submitBtn {
  background-color: $blueColor;
  color: $whiteColor;
  width: 100%;
  margin-top: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.12) inset;
  font-weight: 600;
}
