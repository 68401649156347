@import "styles/variables.scss";

.container {
  width: 292px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $blackColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  flex-shrink: 0;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  min-height: 265px;

  &:hover {
    box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);

    .arrow__icon {
      fill: $hoverColor;
    }
  }

  &--nonCollapsible {
    &:hover {
      box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
    }

    .avatar {
      width: 210px;
      height: 210px;
      margin-top: 35px;
    }

    .speciality {
      margin-top: 50px;
    }

    .location {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .name {
      margin-top: 20px;
    }

    .contacts {
      margin-top: 40px;
    }

    .contacts__item {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    .speciality__item {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
}
