@import "styles/variables.scss";

.tabel {
  //width: 100%;
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 8px;
  border-collapse: separate;
  border-spacing: unset;
  td {
    border: 1px solid $greyColor3;
    background: $whiteColor;
    padding: 0px 10px;
    color: $blackColor;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 1rem;
    font-weight: 500;
    width: 40px;
    min-width: 40px;
    height: 50px;
    &.red {
      color: red;
    }
  }
  th {
    background: $backgroundGrey;
    border: 1px solid $greyColor3;
    padding: 4px 0px;
    color: $greyColor4;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 1rem;
    font-weight: 500;
    width: 40px;
    min-width: 40px;
    &:first-child {
      border-top-left-radius: 8px;
    }
    &:last-child {
      border-top-right-radius: 8px;
    }
  }
  tr {
    min-height: 40px;
    height: 40px;
    white-space: nowrap;
    &:last-child {
      td {
        &:first-child {
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}

.week {
  color: $darkGrey;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-weight: 400;
  display: block;
  &::first-letter {
    text-transform: uppercase;
  }
}

.day {
  color: $greyColor4;
  text-align: center;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: 14px;
  font-weight: 400;
  display: block;
  padding-top: 7px;
}

th.tableTotal {
  width: 100%;
  text-align: center;
  height: 40px;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 18px;
    width: 100%;
    &:first-child {
      color: $darkGrey;
      font-size: 14px;
      font-weight: 400;
      padding-bottom: 4px;
      border-bottom: 1px solid $greyColor3;
    }
    &:last-child {
      padding-top: 4px;
    }
  }
}

td.tableTotal {
  width: 100%;
  text-align: center;
}

td.holiday {
  background-color: #f2f2f2;
}

th.holidayHeader {
  background-color: #e2e2e2;
}

td {
  &.Vacation {
    color: $statusTextVacation;
    background-color: $statusColorVacation;
  }
  &.Medical {
    color: $statusTextMedical;
    background-color: $statusColorMedical;
  }
  &.Truancy {
    color: $statusTextTruancy;
    background-color: $statusColorTruancy;
  }
}

.loader,
.loaderTr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 0px;
  svg {
    width: 120px;
    height: 120px;
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px 0px;
}

.workDateHover {
  cursor: pointer;
  &:hover {
    color: $blueColor;
  }
}
