@import "styles/variables.scss";

.container {
  padding: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.title {
  font-weight: 700;
  font-size: 2rem;
  color: $blackColor;
}

.text {
  font-weight: 700;
  font-size: 2rem;
  color: $greyColor;
  text-align: center;
}

.link {
  width: 100%;
  max-width: 232px;
  text-align: center;
  padding: 8px;
  background-color: $whiteColor;
  color: $blackColor;
  border-radius: 8px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    color: $whiteColor;
    background-color: $hoverColor;
  }
}
