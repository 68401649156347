@import "styles/variables.scss";

.link {
  display: inline-flex;
  align-items: center;

  &--active {
    .icon path {
      fill: $activeColor;
    }

    .title {
      color: $activeColor;
    }
  }

  &:hover {
    .icon path {
      fill: $hoverColor;
    }

    .title {
      color: $hoverColor;
    }
  }

  &:active {
    .icon path {
      fill: $blueColor;
    }

    .title {
      color: $blueColor;
    }
  }
}

.icon path {
  fill: $blackColor;
  transition: fill 0.25s ease-out, width 0.3s ease-out, height 0.3s ease-out;
}

.title {
  font-size: 1rem;
  line-height: 16px;
  transition: color 0.2s ease-out;
  max-width: max-content;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media (max-height: 540px) {
    gap: 20px;
  }
}

.short-menu {
  height: 300px;

  @media (max-height: 540px) {
    height: 250px;
  }

  .icon {
    width: 30px;
    height: 30px;
  }
}

.long-menu {
  margin-right: auto;
  height: 300px;

  @media (max-height: 540px) {
    height: 250px;
  }

  .container {
    gap: 20px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  .title {
    margin-left: 2px;
    width: 200px;
    transition: color 0.2s ease-out, width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}
