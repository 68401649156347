@import "styles/variables.scss";

.container {
  width: 18%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &--workType {
    width: 13%;
    margin-left: 20px;
    display: flex;
  }
}

.container,
.container--workType {
  position: relative;
  [class$="-indicatorContainer"] {
    svg {
      fill: #999;
    }
  }
  &:hover {
    [class$="-placeholder"] {
      color: $blackColor;
    }

    [class$="-indicatorContainer"] {
      svg {
        fill: $blueColor;
        transform: rotateX(180deg);
      }
    }
  }

  [data-value] {
    margin: 0;
    padding: 0;
    color: $blueColor;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 16px;
  }

  [class$="-placeholder"] {
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: $darkGrey;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
  }

  [class$="-singleValue"] {
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: $darkGrey;
  }

  [class$="-indicatorContainer"] {
    svg {
      transition: fill 0.2s cubic-bezier(0.4, 0, 0.2, 1),
        transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }

  & > p:last-child {
    display: none;
    font-weight: 300;
    font-size: 0.5rem;
    line-height: 10px;
    color: $errorColor;
    margin-top: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &[data-error] {
    [class$="-singleValue"] {
      color: $errorColor;
    }

    & > p:last-child {
      display: block;
    }
  }
}

.error {
  width: 100%;
  text-align: inherit;
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 10px;
  margin-top: 2px;
  color: $errorColor2;
}

.option {
  border-radius: 8px;
  overflow: hidden;
  background-color: transparent;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  &.selected {
    background-color: rgba(0, 194, 250, 0.1);
  }
  @media (hover: hover) {
    &:hover {
      background-color: #f2f2f2;
    }
    &.selected:hover {
      background-color: rgba(0, 194, 250, 0.1);
    }
  }
}

.show {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding-right: 8px;
  cursor: pointer;
}

.hide {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding-right: 8px;
}

.label {
  color: #999;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 8px;
}

.container-outline {
  label {
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    color: $darkGrey;
  }
}

.valueWrap {
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 90%;
}

.startAdornment {
  margin-left: 16px;
  path {
    color: #999;
  }
}
