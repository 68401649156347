@import "styles/variables.scss";

.container {
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
  padding: 47px 15px 6px 15px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;

  &:hover {
    box-shadow: 0px 0px 25px -2px rgba(0, 0, 0, 0.16);
  }
}

.description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 37px 0 15px;
  gap: 8%;

  &__title {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 28px;
    color: $totalBlackColor;
    text-align: center;
    width: 100%;
    max-width: 195px;
  }

  &__image-wrapper {
    width: 168px;
    height: 168px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.planning {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  &__text {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    color: $greyColor;
  }
}

.logging {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  &__text {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 16px;
    color: $greyColor;
  }
}

.single--column {
  grid-template-columns: 1fr 1fr 1fr;
  padding: 29px 30px;

  .description {
    padding: 0;
    justify-content: flex-start;

    &__title {
      order: 1;
      text-align: left;
    }

    &__image-wrapper {
      width: 132px;
      height: 132px;
    }
  }

  .planning {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
}
