@import "styles/variables.scss";

.container {
  position: relative;
}

.title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 28px;
  color: $totalBlackColor;
  text-align: center;

  @media (max-width: 500px) {
    text-align: left;
    font-size: 1.2rem;
    padding: 0 18% 0 5%;
  }
}

.controls {
  position: absolute;
  top: 0;
  right: 35px;

  @media (max-width: 600px) {
    right: 0px;
    top: -5px;
  }
}

.new-entry {
  z-index: 1;
  position: relative;

  &__icon {
    width: 41px;
    height: 41px;

    circle {
      fill: $hoverColor;
      transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    path {
      fill: $whiteColor;
    }
  }

  &:hover {
    .new-entry__icon {
      circle {
        fill: $activeColor;
      }
    }
  }
}

.close-new-entry {
  z-index: 1;
  position: relative;

  &__icon {
    width: 41px;
    height: 41px;

    circle {
      fill: $errorColor2;
      transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    path {
      fill: $whiteColor;
    }
  }

  &:hover {
    .close-new-entry__icon {
      circle {
        fill: $errorColor;
      }
    }
  }
}

.body {
  display: grid;
  row-gap: 10px;
  height: max-content;
  min-height: 678px;
  max-height: calc(100vh - 302px);
  overflow-y: auto;
  padding: 35px 35px 30px 30px;
  margin-left: -30px;
  position: relative;

  @media (max-width: 600px) {
    display: block;
  }

  @media (max-width: 1020px) {
    padding: 35px 2px 30px 30px;
    min-height: fit-content;
  }
}

.entry {
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 20px 20px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  height: max-content;

  &:hover {
    box-shadow: 0px 0px 46px -2px rgba(0, 0, 0, 0.16);
  }
}

.entry-title {
  margin-bottom: 15px;

  &__date {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 28px;
    color: $blackColor;
    margin-right: 10px;
  }

  &__user {
    font-weight: 500;
    font-size: 1rem;
    line-height: 18px;
    color: $greyColor;
  }
}

.empty {
  font-weight: 500;
  font-size: 1rem;
  line-height: 18px;
  color: $greyColor;
  text-align: center;
  margin-right: -35px;
}

.list {
  display: grid;
  row-gap: 10px;
  height: max-content;

  @media (max-width: 600px) {
    margin-top: 20px;
    display: block;
  }
}

.editor {
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  & > div {
    width: 100%;
    margin-right: 10px;
  }
}

.save {
  width: 24px;
  height: 24px;

  &:hover {
    .save__icon {
      ellipse {
        fill: $activeColor;
      }
    }
  }

  &:disabled {
    .save__icon {
      ellipse {
        fill: $greyColor;
      }
    }
  }

  &__icon {
    display: block;
    width: 100%;
    height: 100%;

    ellipse {
      fill: $hoverColor;
      transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

.popup {
  position: absolute;
  padding: 20px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  background: #ff76760c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 11px;
  cursor: pointer;
  z-index: 20;
}
