@import "styles/variables.scss";

.container {
  width: 100%;
  background: $whiteColor;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1920px) {
    max-width: 86vw;
  }
}
