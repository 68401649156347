@import "styles/variables.scss";

.container {
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.input {
  height: 24px;
  border: none;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: $blueColor;

  &:hover {
    color: $blackColor;
  }

  &:focus {
    outline: 1px solid $blueColor;
  }
}

.input[placeholder] {
  text-overflow: ellipsis;
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 11px;
  transition: stroke 0.2s ease-out, fill 0.2s ease-out;
  stroke: $blackColor;

  &--fill {
    fill: $blackColor;
    stroke: none;
  }
}

.error {
  width: 100%;
  text-align: inherit;
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 10px;
  margin-top: 2px;
  color: $errorColor2;
}
