.journal-editor .toastui-editor-contents {
  min-height: 60px;
}

.journal-editor .toastui-editor-md-preview {
  height: auto;
  margin-left: 11px;
}

.journal-editor .toastui-editor-md-splitter {
  left: 49.9% !important;
}
