@import "styles/variables.scss";

.container {
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: max-content;
}

.unassigned {
  outline: 1px solid $errorColor;
}

.user {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.name {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 24px;
  color: $blackColor;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.position {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 17px;
  color: $darkGrey;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.details {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.progress {
  display: flex;
  align-items: center;
  column-gap: 15px;
  width: 100%;

  &__bar {
    width: 100%;
    height: 4px;
    position: relative;
    background-color: $backgroundGrey;
    border-radius: 3px;

    &--fill {
      position: absolute;
      left: 0;
      height: 100%;
      border-radius: 10px;
      background-color: $blueColor;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 17px;
    color: $darkGrey;
  }
}

.projects {
  display: flex;
  align-items: center;
  column-gap: 5px;

  &__display {
    width: 20px;
    height: 20px;

    path {
      fill: $greyColor3;
    }
  }

  &__number {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 17px;
    color: $blackColor;
  }
}
