@import "styles/variables.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 20px 24px 20px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  margin-bottom: 40px;

  &:hover {
    box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);
  }

  & > section {
    width: 100%;
  }

  &--open {
    &:hover {
      .arrow__button {
        opacity: 1;
      }
    }

    .arrow__button {
      transform: rotate(180deg);
      opacity: 0;
    }

    .description {
      -webkit-line-clamp: inherit;
    }
  }
}

.editor {
  width: 100%;
}

.title {
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 28px;
  color: $totalBlackColor;
  margin-bottom: 15px;
}

.description {
  font-weight: 300;
  font-size: 1rem;
  line-height: 24px;
  color: $blackColor;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.empty {
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
  color: $greyColor;
}

.arrow__button {
  position: absolute;
  bottom: 0;
  width: 24px;
  height: 24px;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    .arrow__icon {
      fill: $hoverColor;
    }
  }
}

.arrow__icon {
  height: 7.12px;
  width: 15.37px;
  fill: $blackColor;
  transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  padding: 6px 6px 0 6px;
}

.redact {
  margin-left: auto;
  height: 20px;
  width: 20px;

  &:hover {
    .redact__icon {
      fill: $hoverColor;
    }
  }

  &__icon {
    fill: $blackColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.close {
  height: 20px;
  width: 20px;

  &:hover {
    .close__icon {
      fill: $hoverColor;
    }
  }

  &__icon {
    fill: $blackColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.done {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    .done__icon {
      ellipse {
        fill: $greyColor;
      }
    }
  }

  &:hover:not(:disabled) {
    .done__icon {
      ellipse {
        fill: $activeColor;
      }
    }
  }

  &__icon {
    width: 21px;
    height: 21px;
    display: block;

    ellipse {
      fill: $hoverColor;
      transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

.popup {
  position: absolute;
  padding: 20px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  background: #ff76760c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 11px;
  cursor: pointer;
  z-index: 20;
}
