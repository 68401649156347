@import "styles/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .error {
    font-size: 0.6rem;
    margin-top: 10px;
    text-align: center;
    font-weight: 400;
    color: $errorColor2;
  }

  .createProjectForm {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.loading {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    backdrop-filter: blur(2px);
    border-radius: 50%;
  }
}

.input {
  display: none;
}

.label[for="createProjectIcon"] {
  cursor: pointer;
  min-width: 87px;

  &:hover {
    .label__icon {
      fill: $activeColor;
      width: 83px;
      height: 83px;
    }
  }

  .label__image-container {
    width: 132px;
    height: 132px;
    border-radius: 50%;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      width: 115px;
      height: 115px;
    }
  }

  .label__icon {
    display: block;
    width: 87px;
    height: 87px;
    fill: $hoverColor;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      width 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .label__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.redact {
  background: $hoverColor;
  border-radius: 50%;
  width: 27.5px;
  height: 27.5px;
  position: absolute;
  right: 0;
  bottom: -4px;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    height 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    background 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: $whiteColor;
    transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      height 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.label {
  &:hover {
    .redact {
      width: 22px;
      height: 22px;
      background: $activeColor;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}
