@import "styles/variables.scss";

.calendarContainer {
  width: 330px;

  & div[class="react-datepicker__month-container"],
  div[class="react-datepicker__year--container"] {
    padding: 2px 0 10px 0;
  }

  & div[class="react-datepicker__month-wrapper"],
  div[class="react-datepicker__year-wrapper"] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 330px;
    gap: 5px;

    & div[tabindex="0"] {
      flex: 0 1 31%;
      border: 1px solid $halfAccent;
      padding: 6px 8px;
      background-color: transparent;
      color: $blackColor;
    }

    & div[tabindex="-1"] {
      flex: 0 1 31%;
      padding: 6px 8px;

      &:hover {
        background-color: rgba($color: $greyColor, $alpha: 0.2);
      }
    }
  }

  & div[class="react-datepicker__header react-datepicker__header--custom"] {
    padding: 0;
    margin: 2px;
    position: inherit;
  }

  & div[class="react-datepicker__week react-datepicker__week--selected"] {
    border: 1px solid $halfAccent;
    border-radius: 8px;
    width: fit-content;
    margin: auto;

    & div[aria-selected="true"] {
      background-color: transparent;
      color: $blackColor;
    }
  }

  & div[class="react-datepicker__week"]:hover {
    background-color: rgba($color: $greyColor, $alpha: 0.2);
  }

  & div[role="option"]:hover {
    background-color: transparent;
    color: $blackColor;
  }
}

.radioContainer {
  display: flex;
  justify-content: space-between;
  width: 330px;
  border-bottom: 1px solid $backgroundGrey;
  padding: 8px 16px;
}

.radio {
  & label {
    width: 90px;
    padding: 6px 8px;
    border-radius: 8px;
  }

  & input {
    display: none;
  }

  & input:checked + label {
    background-color: transparent;
    color: $blackColor;
    border: 1px solid $halfAccent;
  }
}

.input,
.activeInput {
  width: 330px;
  line-height: 57px;
  background: $whiteColor;
  border: 2px solid transparent;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0 20px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    border: 2px solid $halfAccent;

    & .arrowIcon {
      fill: $halfAccent;
    }

    & .calendarIcon {
      path {
        stroke: $halfAccent;
      }
    }
  }
}

.activeInput {
  border: 2px solid $halfAccent;

  & .arrowIcon {
    fill: $halfAccent;
  }

  & .calendarIcon {
    path {
      stroke: $halfAccent;
    }
  }
}

.date {
  display: flex;
  align-items: center;
  font-weight: 500;
  height: 55px;
  gap: 10px;
}

.calendarIcon {
  margin-top: -3px;
}

.arrowIcon {
  rotate: 90deg;
  margin-top: -10px;
  fill: $greyColor;
}
