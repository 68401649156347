@import "styles/variables.scss";

.modal {
  position: fixed;
  background-color: #6b728056;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  animation: add-opacity 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  form {
    //width: 252px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@keyframes add-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal__body {
  width: max-content;
  box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);
  padding: 40px 20px 20px;
  background-color: $whiteColor;
  border-radius: 8px;
  position: relative;
}

.cross {
  position: absolute;
  right: 6px;
  top: 6px;

  &__icon {
    width: 20px;
    height: 20px;
    fill: $blackColor;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover {
    .cross__icon {
      fill: $activeColor;
    }
  }
}
