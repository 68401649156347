@import "styles/variables.scss";

.dashboardContainer {
  overflow: auto;
  width: 100%;
  display: flex;
  gap: 8px;
  height: 68vh;
}

.dashboarHeader {
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
  margin-bottom: 8px;
  @media (min-width: 1280px) {
    grid-template-columns: 312px 1fr;
  }
}

.dashboardAside {
  width: 56px;
  overflow: hidden;
  height: fit-content;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  min-height: 320px;
  @media (min-width: 1280px) {
    margin-top: 0px;
    width: 312px;
  }
}

.dashboardMain {
  overflow-y: hidden;
  overflow-x: auto;
  width: calc(100% - 56px);
  padding-bottom: 12px;
  height: fit-content;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  min-height: 320px;
  @media (min-width: 1280px) {
    width: calc(100% - 312px);
  }
}

div.buttonGroupRoot {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  button {
    width: 100%;
  }
}

.buttonGroupContainer {
  padding: 8px;
  grid-column: 1/3;
  @media (min-width: 768px) {
    grid-column: auto;
  }
}

.dashboarHeaderActions {
  padding: 12px 8px;
  width: 100%;
  @media (min-width: 768px) {
    padding: 8px 24px;
  }
}

.dashboarHeaderActionsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
}
.dashboarHeaderActionsSelects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
  column-gap: 8px;
  width: 100%;
  align-items: center;
  @media (min-width: 1280px) {
    flex-grow: 1;
    display: flex;
    gap: 8px;
    width: auto;
  }
}
section.dashboarHeaderActionsSelect {
  width: auto;
  margin-left: 0px;
}
.dashboarHeaderActionsCalendarContent {
  display: flex;
  gap: 16px;
  align-items: center;
}
.dashboardSmallCalendar {
  grid-column: 1/3;
  text-align: center;
  justify-content: center;
  display: grid;
  @media (min-width: 768px) {
    grid-column: auto;
  }
}
.searchUsersBorder {
  border-radius: 8px;
  grid-column: 1/3;
  @media (min-width: 768px) {
    grid-column: auto;
  }
}

div.dashboardStatus {
  display: none;
  @media (min-width: 1280px) {
    display: flex;
  }
}
