@import "./reboot.scss";
@import "./fonts.scss";
@import "./variables.scss";
@import "./editor.scss";
@import "./select.scss";

* {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  color: $blackColor;
  // scrollbar-width: thin;
  // scrollbar-color: $halfAccent $greyColor;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

*::-webkit-scrollbar-track {
  background: #f2f2f2;
}

*::-webkit-scrollbar-thumb {
  background-color: $greyColor3;
  border-radius: 30px;
  border: 1px solid $greyColor3;
  cursor: pointer;

  &:hover {
    background-color: $blueColor;
    border-color: $blueColor;
  }
}

html,
body {
  height: 100%;
}

body > #root {
  height: 1px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

main {
  & > section:nth-child(2) {
    height: calc(100vh - 60px);
    overflow-y: auto;

    @media (max-width: 650px) {
      height: 100vh;
    }
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  color: $blackColor;
  text-decoration: none;

  &:hover {
    color: $hoverColor;
  }
}

p {
  margin: 0;
}

img {
  font-size: 11px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s !important;
  -webkit-text-fill-color: $blueColor !important;
}

//react-select
[class$="-MenuPortal"] > [id$="-listbox" i] {
  min-width: max-content;
}

[id^="react-select" i][id$="-listbox" i] {
  min-width: max-content;
}

//Стили для слайдера с табами
.skills-swiper {
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;

  &.swiper {
    margin-left: 30px;
    margin-right: 30px;
  }

  &.swiper-horizontal {
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    text-align: center;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    color: $blackColor;
    background-color: $whiteColor;
    transition: background 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    width: fit-content;
    height: 40px;
    padding: 10px 20px;

    &.activeTab {
      background-color: $activeColor;
      color: $whiteColor;
    }

    &:hover {
      box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.2);
    }
  }

  .swiper-wrapper {
    // justify-content: center;
    height: max-content;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: black !important;
    z-index: 20 !important;
    top: var(--swiper-navigation-top-offset, 42%);
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: 16px !important;
    font-weight: 700;
    top: var(--swiper-navigation-top-offset, 42%);
    z-index: 20 !important;
  }

  .swiper-button-next {
    right: var(--swiper-navigation-sides-offset, -6px);
  }

  .swiper-button-prev {
    left: var(--swiper-navigation-sides-offset, -6px);
  }
}
.form-avatar-wrap section {
  cursor: pointer;
  .redact-field {
    right: unset;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    opacity: 0;
    transition: opacity 300ms ease;
    width: 28px;
    height: 28px;
  }
}

@media (hover: hover) {
  .form-avatar-wrap section:hover {
    .redact-field {
      width: 28px;
      height: 28px;
      opacity: 0.6;
      transform: translate(-50%, -50%);
    }
  }
}

.react-datepicker.calendar-dashboard {
  .react-datepicker__day:hover {
    background-color: transparent;
    color: #000;
  }
  .react-datepicker__day.react-datepicker__day--selected.custom {
    background-color: transparent;
    color: #000;
  }
  .react-datepicker__week {
    border-radius: 16px;
    transition: border 300ms ease;
    border: 1px solid transparent;
    &:has(> .custom) {
      border: 1px solid #00c2fa;
      transition: border 300ms ease;
    }
    @media (hover: hover) {
      &:hover {
        border: 1px solid #00c2fa;
        transition: border 300ms ease;
      }
    }
  }
}
