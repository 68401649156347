@import "styles/variables.scss";

.members {
  display: flex;
  align-items: center;
  width: 78%;

  &__image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;
    flex-shrink: 0;
  }

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__info {
    overflow: hidden;
  }

  &__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $blackColor;
    margin-bottom: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 1020px) {
      width: 95%;
      white-space: pre-wrap;
    }
  }

  &__role,
  &__office {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 14px;
    color: $greyColor;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.controls {
  margin-left: auto;
  padding-left: 15px;
  padding-right: 10px;
}

.delete {
  width: max-content;
  height: max-content;

  &:hover {
    .delete__icon {
      fill: $errorColor;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: block;
    fill: $greyColor;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.add {
  width: max-content;
  height: max-content;

  &:hover {
    .add__icon {
      fill: $hoverColor;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: block;
    fill: $greyColor;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.confirm-container {
  display: flex;
  align-items: center;
}

.confirm {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  &:hover {
    .confirm__icon {
      ellipse {
        fill: $errorColor;
      }
    }
  }

  &__icon {
    width: 21px;
    height: 21px;
    display: block;

    ellipse {
      fill: $errorColor2;
      transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

.cancel {
  width: max-content;
  height: max-content;

  &:hover {
    .cancel__icon {
      fill: $blackColor;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    display: block;
    fill: $greyColor;
    transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.confirm-text {
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 14px;
  color: $errorColor;
}
