@import "styles/variables.scss";

.container {
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 30px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    box-shadow: 0px 0px 25px -2px rgba(0, 0, 0, 0.16);
  }
}

.form {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr minmax(200px, 236px);
  column-gap: 30px;
  row-gap: 5px;
  grid-template-areas:
    "first second"
    "first third"
    "first fourth"
    "first fifth"
    "first sixth"
    "first seventh"
    "first eighth";

  @media screen and (max-width: 1280px) {
    column-gap: 15px;
  }

  section:nth-child(1) {
    grid-area: first;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  section:nth-child(2) {
    grid-area: second;
  }
  section:nth-child(3) {
    grid-area: third;
  }
  section:nth-child(4) {
    grid-area: fourth;
  }
  section:nth-child(5) {
    grid-area: fifth;
  }
  section:nth-child(6) {
    grid-area: sixth;
  }
  button:nth-child(7) {
    grid-area: seventh;
  }
  button:nth-child(8) {
    grid-area: eighth;
  }

  section {
    width: 100%;
    margin-left: 0;
    display: block;

    &[data-error] {
      input[name="telegram"] {
        background: url("/assets/img/icons/telegram-error.svg") no-repeat right !important;
        background-position-x: 6px !important;
      }

      input:not(input[id^="react-select"]):not(input[type="file"]):not(
          input[name="name"]
        ) {
        color: $errorColor;
        outline: 1px solid $errorColor2;
        -webkit-text-fill-color: #ff5656 !important;
      }
    }
  }

  input[name="name"]:not(input[id^="react-select"]):not(input[type="file"]) {
    box-shadow: none;
    border: none;
    font-weight: 500;
    font-size: 1rem;
    line-height: 18px;
    color: $blackColor;

    &::placeholder {
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      color: $blackColor;
    }

    &:hover {
      padding-left: 35px;
      background: url("/assets/img/icons/redact-black.svg") no-repeat right;
      background-position-x: 6px;

      &::placeholder {
        color: $greyColor;
      }
    }

    &:focus {
      padding-left: 35px;
      background: url("/assets/img/icons/redact-accent.svg") no-repeat right;
      background-position-x: 6px;
      outline: none;
    }
  }

  input:not(input[id^="react-select"]):not(input[type="file"]) {
    width: 100%;
    height: max-content;
    border: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
      inset 0px 0px 4px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    padding: 4px 8px;
    text-align: center;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 16px;
    caret-color: $blackColor;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      background 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      padding 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    color: $blueColor;
    text-overflow: ellipsis;

    &:focus {
      color: $halfAccent;
      outline: 1px solid $blueColor;

      &[name="telegram"] {
        background: url("/assets/img/icons/telegram.svg") no-repeat right;
        background-position-x: 6px;
      }

      &::placeholder {
        color: $blackColor;
      }
    }

    &::placeholder {
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      color: $greyColor;
    }

    &:hover {
      &[name="telegram"] {
        background: url("/assets/img/icons/telegram.svg") no-repeat right;
        background-position-x: 6px;
      }

      &::placeholder {
        color: $blackColor;
      }
    }

    &[name="telegram"] {
      background: url("/assets/img/icons/telegram-gray.svg") no-repeat right;
      background-position-x: 6px;
      padding-left: 30px;
      padding-right: 30px;
    }

    &:not(:placeholder-shown) {
      &[name="telegram"] {
        background: url("/assets/img/icons/telegram-active.svg") no-repeat right;
        background-position-x: 6px;
      }
    }
  }

  button[type="submit"] {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-color: $whiteColor;
    color: $blackColor;
    border-radius: 8px;
    box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
    border: none;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: inherit;
    height: max-content;

    &:hover {
      color: $whiteColor;
      background-color: $hoverColor;
    }

    &:active {
      background-color: $activeColor;
    }

    &:disabled {
      background-color: $greyColor;
    }
  }
}

.container--single {
  padding: 20px 30px;

  .form {
    grid-template-areas:
      "first second third last"
      "first second fourth last"
      "first second fifth last"
      "first second sixth last";
    grid-template-columns: 0.3fr minmax(243px, 0.65fr) 1fr 1fr;
    column-gap: 24px;
    row-gap: 15px;

    section:nth-child(2):not(input[id^="react-select"]):not(
        input[type="file"]
      ) {
      align-self: center;
      width: max-content;

      @media screen and (max-width: 1440px) {
        width: 230px;
      }

      input[name="name"] {
        font-size: 1.5rem;
        text-align: left;
      }
    }
    section:nth-child(3) {
      max-width: 236px;
      margin: 0 auto;
    }
    section:nth-child(4) {
      max-width: 236px;
      margin: 0 auto;
    }
    section:nth-child(5) {
      max-width: 236px;
      margin: 0 auto;
    }
    section:nth-child(6) {
      max-width: 236px;
      margin: 0 auto;
    }
    button:nth-child(7) {
      align-self: center;
      max-width: 236px;
      margin: 0 auto;
    }
  }
}
