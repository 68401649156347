@import "styles/variables.scss";

.redact-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  animation: add-opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.cross {
  &:hover {
    .cross__icon {
      fill: $activeColor;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    fill: $blackColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);

    &--close {
      display: none;
    }
  }
}

.redact {
  margin-left: auto;

  &:hover {
    .redact__icon {
      fill: $hoverColor;
    }
  }

  &:active {
    .redact__icon {
      fill: $blueColor;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    fill: $blackColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
}
