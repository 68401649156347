@import "styles/variables.scss";

.container {
  width: 292px;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $blackColor;
  border-radius: 11px;
  flex-shrink: 0;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;

  &--nonCollapsible {
    box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);

    &:hover {
      box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
    }

    .avatar {
      width: 210px;
      height: 210px;
      margin-top: 19px;
    }

    .speciality {
      margin-top: 40px;
    }

    .location {
      margin-top: 40px;
      margin-bottom: 40px;
    }

    .name {
      margin-top: 20px;
    }

    .contacts {
      margin-top: 20px;
    }

    .contacts__item {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    .speciality__item {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
}

.avatar {
  width: 168px;
  height: 168px;
  border-radius: 100px;
  object-fit: cover;
  margin-top: 20px;
  transition: width 0.15s ease-out, height 0.15s ease-out;

  &--min {
    margin-top: -14px !important;
    width: 98px !important;
    height: 98px !important;
  }
}

.name {
  margin-top: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
}

.job {
  margin-top: 5px;
  color: $greyColor;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.contacts {
  margin-top: 20px;

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__text {
    color: $greyColor;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    transition: color 0.2s ease-out;
  }

  a:hover {
    .contacts__text {
      color: $hoverColor;
    }

    .icon {
      stroke: $hoverColor;
    }

    .icon--fill {
      stroke: none;
      fill: $hoverColor;
    }
  }
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 11px;
  transition: stroke 0.2s ease-out, fill 0.2s ease-out;
  stroke: $blackColor;

  &--fill {
    fill: $blackColor;
    stroke: none;
  }
}

.birthday {
  display: flex;
  flex-direction: column;
}

.speciality {
  display: flex;
  flex-direction: column;
  margin-top: 34px;

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}

.itemTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $blackColor;
}

.itemValue {
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $greyColor;
  max-width: 145px;
}

.location {
  display: flex;
  flex-direction: column;
  margin-top: 34px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 10px;
    }
  }
}

.info {
  width: 90%;
  margin: 0 10px 0 10px;
  display: flex;
  flex-direction: column;
}
