@import "styles/variables.scss";

.entry {
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 20px 20px;
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }

  &:hover {
    box-shadow: 0px 0px 46px -2px rgba(0, 0, 0, 0.16);
  }

  &--open {
    .arrow__button {
      transform: rotate(180deg);
    }
  }

  &--confirm {
    box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.12),
      inset 0px 0px 14px rgba(28, 28, 28, 0.27);

    &:hover {
      box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.12),
        inset 0px 0px 14px rgba(28, 28, 28, 0.27);
    }
  }
}

.entry-title {
  margin-bottom: 15px;

  &__date {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 28px;
    color: $blackColor;
    margin-right: 10px;
  }

  &__user {
    font-weight: 500;
    font-size: 1rem;
    line-height: 18px;
    color: $greyColor;
  }
}

.empty {
  font-weight: 500;
  font-size: 1rem;
  line-height: 18px;
  color: $greyColor;
  text-align: center;
  margin-right: -35px;
}

.arrow__button {
  position: absolute;
  bottom: 0;
  width: 24px;
  height: 24px;
  transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  align-self: center;

  &:hover {
    .arrow__icon {
      fill: $hoverColor;
    }
  }
}

.arrow__icon {
  height: 7.12px;
  width: 15.37px;
  fill: $blackColor;
  transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

.controls {
  position: absolute;
  right: 6px;
  top: 6px;
  height: calc(100% - 16px);
  z-index: 20;
}

.change,
.confirm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.redact {
  height: 20px;
  width: 20px;

  &:hover {
    .redact__icon {
      fill: $hoverColor;
    }
  }

  &__icon {
    fill: $greyColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.delete {
  height: 24px;
  width: 24px;

  &:hover {
    .delete__icon {
      fill: $errorColor2;
    }
  }

  &__icon {
    fill: $greyColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.check {
  width: 29px;
  height: 29px;

  svg {
    display: block;
    width: 29px;
    height: 29px;
  }

  &:hover {
    .check__icon {
      ellipse {
        fill: $errorColor;
      }
    }
  }

  &__icon {
    ellipse {
      fill: $errorColor2;
      transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

.cross {
  width: 30px;
  height: 30px;

  &:hover {
    .cross__icon {
      circle {
        fill: $activeColor;
      }
    }
  }

  &__icon {
    display: block;
    width: 100%;
    height: 100%;

    circle {
      fill: $hoverColor;
      transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

.editor {
  display: flex;
  align-items: center;

  & > div:not(:last-child) {
    width: 100%;
    margin-right: 10px;
  }
}

.save {
  width: 24px;
  height: 24px;

  &:hover {
    .save__icon {
      ellipse {
        fill: $activeColor;
      }
    }
  }

  &:disabled {
    .save__icon {
      ellipse {
        fill: $greyColor;
      }
    }
  }

  &__icon {
    display: block;
    width: 100%;
    height: 100%;

    ellipse {
      fill: $hoverColor;
      transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

.cancel {
  width: 24px;
  height: 24px;

  &:hover {
    .cancel__icon {
      circle {
        fill: $errorColor;
      }
    }
  }

  &__icon {
    display: block;
    width: 100%;
    height: 100%;

    circle {
      fill: $errorColor2;
      transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}

.editor__controls {
  height: 50%;
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.popup {
  position: absolute;
  padding: 20px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(6px);
  background: #ff76760c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  border-radius: 11px;
  cursor: pointer;
  z-index: 20;
}
