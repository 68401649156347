@import "styles/variables.scss";

.container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 30px 30px;
  overflow-y: hidden;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
}

.plus {
  position: absolute;
  top: 6px;
  right: 6px;

  &__icon {
    width: 41px;
    height: 41px;

    circle {
      fill: $hoverColor;
      transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }

    path {
      fill: $whiteColor;
    }
  }

  &:hover {
    .plus__icon {
      circle {
        fill: $activeColor;
      }
    }
  }
}

.tabs {
  margin-top: 12px;
  padding: 0 30px;
  width: 100%;
  border-bottom: 1px solid $greyColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 18px;
}

.typeSkill {
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.form {
  margin-top: 10px;

  label {
    margin-top: 30px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  section {
    width: 100%;
  }

  input {
    width: 100%;
    margin-top: 6px;
    text-align: left;
    padding: 10px;
    height: 36px;
    caret-color: $blackColor;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    text-overflow: ellipsis;

    &:focus {
      color: $halfAccent;

      &::placeholder {
        color: $blackColor;
      }
    }

    &::placeholder {
      transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      color: $greyColor;
    }

    &:hover {
      &::placeholder {
        color: $blackColor;
      }
    }
  }

  button {
    width: 100%;
    height: 36px;
    box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 30px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $totalBlackColor;
    transition: background 0.3s cubic-bezier(0.4, 0, 0.2, 1),
      color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      background-color: rgba(0, 194, 250, 0.6);
      color: $whiteColor;
    }

    &:active {
      background-color: $activeColor;
    }
  }
}

.table {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  overflow-y: auto;
}

.skeleton-container {
  width: 100%;
}

.skeletonTable {
  margin-top: 16px;
  width: 100%;
}

.navigationContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    height: max-content;
    width: max-content;

    &:hover {
      .prev,
      .next {
        fill: $hoverColor;
      }
    }
  }
}

.prev,
.next {
  fill: $blackColor;
  transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.prev {
  transform: rotate(90deg);
}

.next {
  transform: rotate(-90deg);
}
