@import "styles/variables.scss";

.container {
  display: flex;
  width: 100%;
  height: 16px;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
}

.input {
  height: 24px;
  border: none;
  border-radius: 4px;
  width: 14px !important;
  height: 14px !important;
  margin-top: 0 !important;
  margin-right: 4px;
  background-color: $blueColor;

  &:focus {
    outline: 1px solid $blueColor;
  }
}

input[type="checkbox"]:checked,
input[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

input[type="checkbox"]:checked + label,
input[type="checkbox"]:not(:checked) + label {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}

input[type="checkbox"]:checked + label:before,
input[type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 14px;
  height: 14px;
  border: 1px solid $blackColor;
  border-radius: 4px;
  background-color: #ffffff;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
  content: "";
  position: absolute;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

input[type="checkbox"]:checked + label:after,
input[type="checkbox"]:not(:checked) + label:after {
  left: 2.3px;
  top: 4px;
  width: 10px;
  height: 5px;
  border-radius: 1px;
  border-left: 2px solid $blueColor;
  border-bottom: 2px solid $blueColor;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.push {
  margin-top: 6px !important;
}
