@import "styles/variables.scss";

.container {
  width: 100%;
  max-width: 826px;
  min-height: 543px;
  background: $whiteColor;
  border-radius: 20px;
  box-shadow: 0px 0px 74px -2px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  padding: 30px;
}

.img-container {
  width: 294px;
  height: 118px;

  &__logo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
