@import "styles/variables.scss";

.skeleton-container {
  width: 100%;
}

.error {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 10px;
  margin-top: 10px;
  color: $errorColor2;

  &.createProjectFormError {
    text-align: right;
    margin-top: 0;
    font-size: 0.85rem;
  }

  &.projectEditFormError {
    margin-top: -5px;
    margin-bottom: 15px;
    font-size: 0.85rem;
  }
}
