.mainContainer {
  display: grid;
  // grid-template-columns: 292px minmax(300px, 1064px) 264px;
  grid-template-columns: 292px minmax(300px, 100%) 264px;
  width: 100%;
  padding: 30px;
  gap: 30px;

  @media screen and (max-width: 1279px) {
    // grid-template-columns: 292px minmax(300px, 548px);
    grid-template-columns: 292px minmax(300px, 100%);
    grid-template-rows: 1fr;
  }
}

.profileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  //   flex-shrink: 0;
  //   width: 292px;
  height: max-content;
  gap: 10px;
}

.position {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
}

.profileOpen {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calendarOpen {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
