@import "styles/variables.scss";

.container {
  width: 11%;
  margin-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.input {
  width: 100%;
  height: 24px;
  border: none;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  color: $blueColor;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;

  &:focus {
    outline: 1px solid $blueColor;
  }
}

.input::-webkit-calendar-picker-indicator {
  display: none;
}

.error {
  width: 100%;
  text-align: inherit;
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 10px;
  margin-top: 2px;
  color: $errorColor2;
}
