@import "styles/variables.scss";

.container {
  display: flex;
  align-items: center;
  width: 35%;
  justify-content: center;
  flex-direction: column;

  & > p:last-child {
    display: none;
    font-weight: 300;
    font-size: 0.5rem;
    line-height: 10px;
    color: $errorColor;
    margin-top: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &[data-error] {
    textarea {
      color: $errorColor;
    }

    & > p:last-child {
      display: block;
    }
  }
}

.textarea {
  height: 222px;
  border: none;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  padding: 10px;

  &:focus {
    outline: 1px solid $blueColor;
  }
}

.textarea[placeholder] {
  text-overflow: ellipsis;
}

.error {
  width: 100%;
  text-align: inherit;
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 10px;
  margin-top: 2px;
  color: $errorColor2;
}
