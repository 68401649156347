@import "styles/variables.scss";

.container {
  background: $whiteColor;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.progress {
  display: flex;
  align-items: center;
  column-gap: 15px;
  width: 100%;

  &__bar {
    width: 100%;
    height: 4px;
    position: relative;
    background-color: $backgroundGrey;
    border-radius: 3px;

    &--fill {
      position: absolute;
      left: 0;
      height: 100%;
      border-radius: 10px;
      background-color: $blueColor;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 17px;
    color: $darkGrey;
  }
}

.totals-row,
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.totals-row[data-unit="week"],
.row[data-unit="week"] {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.totals-row[data-unit="month"],
.row[data-unit="month"] {
  grid-template-columns: 1fr;
}

.totals-row {
  padding: 20px;
  border-bottom: 1px solid $backgroundGrey;
}

.total-cell {
  display: flex;
  align-items: center;
  column-gap: 5px;

  span {
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    color: $blackColor2;
  }
}

.dayOff,
.dayOff > span {
  color: $greyColor;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
}

.clock-icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;

  path {
    stroke: $greyColor3;
  }
}

.row {
  padding: 20px;
  border-bottom: 1px solid $backgroundGrey;
}

.arrow {
  width: 100%;
  padding: 17px 0 8px 0;
  text-align: center;
  cursor: pointer;

  &:hover {
    .arrow-icon {
      fill: $hoverColor;
    }
  }
}

.arrow-icon {
  width: 16px;
  height: 16px;
  fill: $greyColor3;
  transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &--open {
    fill: $blackColor;
    transform: rotate(180deg);
  }
}

.slider {
  width: 100%;
}
