@import "styles/variables.scss";

.table {
  width: 100%;
  margin: 20px 0px;
  padding-bottom: 30px;
}

.header {
  height: 46px;
  border-bottom: 0.5px solid $greyColor;

  &__th {
    background: linear-gradient(
      180deg,
      $tableGradientBlue 0%,
      rgba(169, 236, 255, 0.68) 100%
    );
    position: relative;
    cursor: pointer;
    text-align: center;

    :after {
      content: "";
      position: absolute;
      top: 20%;
      right: 0;
      height: 60%;
      border-right: 2px solid $greyColor;
    }
  }

  &__th:first-child {
    border-radius: 8px 0 0 0;
    padding-left: 14px;
    text-align: left;
    cursor: default;

    svg {
      display: none;
    }
  }

  &__th:last-child {
    border-radius: 0 8px 0 0;
    border-right: none;

    :after {
      content: "";
      position: absolute;
      right: 0;
      height: 20px;
      border-right: none;
    }
  }

  &__item {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.row {
  height: 46px;

  &:nth-child(odd) {
    background-color: $whiteColor;
  }

  &:nth-child(even) {
    background-color: $backgroundGrey;
  }

  &:last-child {
    background-color: $tableBlue;
  }
}

.cell {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  border-bottom: 0.5px solid $greyColor;
  word-break: break-word;

  &:first-child {
    text-align: left;
    padding-left: 14px;
  }
}

.resizer {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 15px;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.isResizing {
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
