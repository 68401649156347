.mainContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 30px;
  gap: 30px;

  @media (max-width: 650px) {
    padding: 0 0 0 10px;
  }
}
