@import "styles/variables.scss";

.container {
  width: 100%;
}

.sample {
  width: 92%;
}

.table {
  width: 92%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 30px;
  color: $greyColor;

  .header {
    display: flex;
    width: 100%;
    border-bottom: 1px solid $greyColor3;
    padding: 20px;

    .weekday {
      width: 15%;
      margin-left: 20px;

      &:first-child {
        margin-left: 0px;
      }

      .date {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $blackColor;
        overflow: hidden;
        flex-wrap: nowrap;
        text-overflow: ellipsis;
      }

      .day {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $greyColor;
        margin-top: 5px;
        overflow: hidden;
        flex-wrap: nowrap;
        text-overflow: ellipsis;
      }
    }

    .dayoff {
      width: 8%;
      margin-left: 20px;

      .date {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $orangeColor;
        display: block;
        overflow: hidden;
        flex-wrap: nowrap;
        text-overflow: ellipsis;
      }

      .day {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $greyColor;
        margin-top: 5px;
        overflow: hidden;
        flex-wrap: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .columns {
    display: flex;
    padding: 0 20px;

    .column {
      display: flex;
      flex-direction: column;
      width: 15%;
      // margin-left: 20px;
      margin: 0px 0 20px 20px;
    }

    .column:nth-child(1) {
      margin-left: 0px;
    }

    .column:nth-child(6) {
      width: 8%;
      margin-left: 20px;
    }

    .column:nth-child(7) {
      width: 8%;
      margin-left: 20px;
    }
  }
}

.project {
  margin-top: 20px;

  &--name {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $blackColor;
  }

  &--hours {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $greyColor;
  }
}
