@import "styles/variables.scss";

.tabs {
  display: flex;
  align-items: center;
  gap: 24px;
  overflow: scroll;
  scrollbar-width: none;
  scrollbar-color: none;
}
