@import "styles/variables.scss";

.arrow {
  margin: 8.25px 0 8.63px 0;
  transition: transform 0.25s ease-out;

  &__icon {
    height: 7.12px;
    width: 15.37px;
    fill: $blackColor;
    transition: fill 0.25s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:active &__icon {
    fill: $blueColor;
  }

  &:hover &__icon {
    fill: $hoverColor;
  }

  &--open {
    transform: rotate(180deg);
  }
}
