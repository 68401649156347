.calendar {
  border: none;
  margin-top: 0px !important;
  width: 100%;
}
.react-datepicker {
  border: none;
}

.react-datepicker-container-shadow .react-datepicker__input-container input {
  border: none;
  color: #00c2fa;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  width: 100%;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 5px;
}
.react-datepicker-container-outline .react-datepicker__input-container input {
  height: 40px;
  border: 2px solid #f2f2f2;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  color: #313131;
  transition: border 300ms ease;
  padding: 8px 16px;
}

.react-datepicker-container-outline
  .react-datepicker__input-container
  input:focus {
  outline: none;
  border: 2px solid #00c2fa;
  transition: border 300ms ease;
}

.react-datepicker__input-container input:disabled::placeholder {
  color: #181818;
}

.react-datepicker__input-container input:disabled {
  box-shadow: none;
  background: none;
}

.react-datepicker__input-container input:focus {
  outline: 1px solid #00c2fa;
}
/*  */
.calendarContainer .react-datepicker__day--highlighted {
  background-color: #00c2fa;
  color: white !important;
}

.calendarContainer .react-datepicker__week:hover {
  background-color: #e2e2e2;
}

.calendarContainer .react-datepicker__day:hover {
  background-color: #e2e2e2 !important;
  color: #181818 !important;
}

.calendarContainer .react-datepicker__day--disabled:hover {
  background-color: #00c2fa !important;
  color: white !important;
}
/*  */

.react-datepicker__input-container input::placeholder {
  color: #b6b6b6;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.react-datepicker__input-container input::placeholder:hover {
  color: #181818;
}

.react-datepicker__header {
  background-color: white;
  border-bottom: none;
  padding: 8px 0 0 0;
}

.react-datepicker__month-container {
  float: none;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
  padding: 10px;
  border-radius: 4px;
}

.react-datepicker__current-month {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #181818;
  border-bottom: 1px solid #b6b6b6;
  padding-bottom: 15px;
}

.react-datepicker__navigation-icon--next {
  background-image: url("assets/img/icons/arrow-next.svg");
  background-size: cover;
  width: 16px;
  height: 16px;
  right: 20px;
}

.react-datepicker__navigation--previous {
  background-image: url("assets/img/icons/arrow-prev.svg");
  background-size: cover;
  width: 16px;
  height: 16px;
  left: 44px;
}

.react-datepicker__navigation-icon::before {
  border: none;
  display: none;
}

.react-datepicker__navigation {
  width: 16px;
  height: 16px;
  top: 18px !important;
}

.react-datepicker__navigation--next {
  right: 44px;
}

.react-datepicker__day-name {
  color: #b6b6b6;
}

.react-datepicker__month {
  margin: 6px 0.4em 0 0.4em;
  font-size: 14px;
  font-weight: 500;
}

.react-datepicker__day--outside-month {
  font-weight: 300;
  color: #b6b6b6;
}

.react-datepicker__day:hover {
  background-color: #66dafc;
  color: #ffffff;
}

.react-datepicker__day--selected {
  background-color: #00c2fa;
  color: #ffffff;
  border-color: #66dafc;
  outline-color: #66dafc;
}

.react-datepicker__day--selected:hover {
  background-color: #00c2fa;
  color: #ffffff;
}

.react-datepicker__day--keyboard-selected {
  background-color: #66dafc;
  color: #ffffff;
  border-color: #00c2fa;
  outline-color: #00c2fa;
}

.react-datepicker__triangle {
  display: none;
}
