@import "styles/variables.scss";

.form {
  section {
    width: clamp(110px, 8vw, 200px);
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
}

.btn {
  background: $btnReports;
  box-shadow: 0px 0px 11px -2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $whiteColor;
  padding: 3px 20px;
  margin-left: 10px;
  transition: background 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    background: $blueColor;
  }

  &.saccess {
    background: url("../../../assets/img/icons/simple-check.png") $blueColor;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 14px;
    color: rgba(0, 0, 0, 0);
  }

  @media screen and (max-width: 1279px) {
    font-size: 12px;
  }
}

.error {
  width: 100%;
  text-align: center;
  font-size: 0.65rem;
  margin: 4px;
  color: $errorColor2;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
