@import "styles/variables.scss";

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.container-shadow {
  align-items: center;
  width: 35%;
}

.container-outline {
  align-items: flex-start;
  label {
    padding-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    color: $darkGrey;
  }
}

.input {
  min-height: 24px;
}

.input[placeholder] {
  text-overflow: ellipsis;
}

.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 11px;
  transition: stroke 0.2s ease-out, fill 0.2s ease-out;
  stroke: $blackColor;

  &--fill {
    fill: $blackColor;
    stroke: none;
  }
}

.error {
  width: 100%;
  text-align: inherit;
  font-weight: 400;
  font-size: 0.5rem;
  line-height: 10px;
  margin-top: 2px;
  color: $errorColor2;
}

.input-outline {
  height: 40px;
  border: 2px solid $backgroundGrey;
  border-radius: 8px;
  font-weight: 500;
  font-size: 1rem;
  color: $greyColor4;
  transition: border 300ms ease;
  padding: 8px 16px;
  &:focus {
    outline: none;
    border: 2px solid $activeColor;
    transition: border 300ms ease;
  }
}

.input-shadow {
  height: 24px;
  border: none;
  box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  text-align: center;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: $blueColor;

  &:focus {
    outline: 1px solid $blueColor;
  }
}
