@import "styles/variables.scss";

.container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table {
  width: 100%;
}

.header {
  width: 100%;
  background: $whiteColor;
  margin-bottom: 40px;
  display: flex;

  &__item {
    display: flex;
    align-items: center;
    height: 46px;
    justify-content: center;
  }

  &__item:first-child {
    width: 40%;
    padding-left: 12px;
  }

  &__item:nth-child(2) {
    width: 10%;
    margin-left: 20px;
  }

  &__item:nth-child(3) {
    width: 10%;
    margin-left: 20px;
  }

  &__item:nth-child(4) {
    width: 10%;
    margin-left: 20px;
  }

  &__item:nth-child(5) {
    width: 10%;
    margin-left: 20px;
  }

  &__item:nth-child(6) {
    width: 10%;
    margin-left: 20px;
  }
}

.header p {
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rows {
  display: grid;
  row-gap: 35px;
  margin-top: 30px;
}

.oneRow {
  display: flex;
  background: $whiteColor;
  border: none;
  height: max-content;
  min-height: 40px;
  margin-top: 0;
  cursor: auto;
  align-items: center;
  position: relative;

  .delete {
    position: absolute;
    right: -24px;
    top: 6px;
    opacity: 0;
    z-index: -10;
    width: 24px;
    height: 24px;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:hover {
    color: $greyColor;
    cursor: pointer;

    .delete {
      opacity: 1;
      z-index: 1;

      svg {
        fill: $greyColor;
        transition: fill 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:hover {
        svg {
          fill: $errorColor2;
        }
      }
    }
  }
}

.edit {
  opacity: 0;
  margin: 0 0 0 -18px;
  transition: margin 0.3s cubic-bezier(0.4, 0, 0.2, 1),
    opacity 0.1s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  display: block;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.cell {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: $greyColor;

    .edit {
      opacity: 1;
      margin: 0 4px 0 0;
    }
  }

  @media screen and (max-width: 1279px) {
    font-size: 14px;
  }

  &:nth-child(2) {
    width: 40%;
    justify-content: flex-start;
  }

  &:nth-child(3) {
    width: 10%;
    margin-left: 20px;
  }

  &:nth-child(4) {
    width: 10%;
    margin-left: 20px;
  }

  &:nth-child(5) {
    width: 10%;
    margin-left: 20px;
  }

  &:nth-child(6) {
    width: 10%;
    margin-left: 20px;
  }

  &:nth-child(7) {
    width: 10%;
    margin-left: 20px;
    text-align: center;
  }

  @media screen and (max-width: 1279px) {
    font-size: 14px;
  }
}

.cellContainer {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  white-space: normal;
  overflow: hidden;

  p {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &:not(.cellContainerSimple):hover {
    color: $greyColor;
    cursor: pointer;

    p {
      color: $greyColor;
    }
  }
}

.cell {
  section {
    width: 100%;
    margin-left: 0;

    input {
      width: 100%;
    }
  }
}

.inputContainer {
  width: 100%;
}

.error {
  width: max-content;
  max-width: 400px;
  text-align: inherit;
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 20px;
  color: $errorColor2;
  text-align: center;
}

.pagination {
  width: 100%;
  margin-top: auto;

  @media (max-width: 650px) {
    margin-top: 0;
  }
}
